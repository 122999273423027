.product-description {
    margin-bottom: 65px;

    ul.product-presentation {
        text-align: center;
        margin-bottom: 10px;
        border: 1px solid #dfdfdf;

        li {
            position: relative;
            padding-top: 75px;
            padding-bottom: 35px;
            text-align: center;

            img {
                display: inline-block;
            }

            &.slick-active {}

            .like {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 40px;
                height: 35px;
                padding: 0;
                background-color: transparent;

                &:before {
                    content: '\f08a';
                    display: block;
                    font-family: 'FontAwesome';
                    font-size: 37px;
                    line-height: 1;
                    color: $baseColorRed;
                }

                &[data-chacked="true"] {
                    &:before {
                        content: '\f004';
                    }
                }
            }

            .hot-marker {
                position: absolute;
                top: 20px;
                left: 20px;
            }
        }
    }

    ul.slider-nav {
        width: 100%;

        li {
            width: auto;
            margin-right: 7px;
            margin-left: 7px;
            display: inline-block;
            padding-top: 7px;
            padding-bottom: 7px;
            border: 1px solid #dfdfdf;
            text-align: center;

            img {
                display: inline-block;
                width: 100%;
            }

            &.slick-current {
                border: 1px solid $baseColorRed;
            }
        }
    }

    .content-wrapper {
        position: relative;

        // padding-top: 10px;

        .rating-stars {
            position: absolute;
            top: 7px;
            right: 15px;
        }

        .product-title-wrapper {
            h3 {
                margin-bottom: 0px;
                padding-right: 105px;
                text-align: left;
                font-size: 30px;
                line-height: 1.2;
                text-transform: uppercase;
            }

            .short-description {
                display: block;
                margin-bottom: 26px;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 18px;
            }
        }

        .product-info {
            .price {
                margin-bottom: 29px;
                display: inline-block;
                font-size: 24px;
                color: $baseColorRed;
                font-weight: bold;

                .currency {
                    display: inline-block;
                    color: #000000;
                    font-weight: 300;
                    font-size: 20px;
                    font-style: normal;
                }
            }

            .old-price {
                margin-left: 20px;
                text-decoration: line-through;
            }

            .technical-info {
                margin-bottom: 22px;
                display: block;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            ul.technical-sheet {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .buying-process {
            // margin-top: 100px;
            text-align: right;

            .buy-product {
                .counter-wrapper {
                    position: relative;
                    margin-bottom: 40px;

                    .quantity {
                        margin-right: 20px;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 26px;
                            height: 26px;
                            margin-right: 20px;
                            margin-bottom: -7px;
                            background-image: url("../image/basket.png");
                            background-size: contain;
                            background-repeat: no-repeat;
                        }

                        &:before {
                            position: absolute;
                            top: 0;
                            right: 120px;
                            margin-right: 0;
                        }
                    }

                    button {
                        width: 26px;
                        height: 26px;
                        padding: 0;
                        line-height: 26px;

                        // &:hover {
                        //     background-color: darken($baseBgColorPale, 10deg);
                        // }
                   
                    }
                }

                button {
                    // padding: 15px;
                    // width: 100%;
                    // height: auto;
                    max-width: 100%;

                    &[onclick^="cart.add"]:hover {
                        background-color: $baseColorRed;
                    }
                }

                a {
                    margin-top: 5px;
                    margin-bottom: 47px;
                    color: $baseColorRed;
                    font-size: 12px;
                    font-weight: 400;
                }

                p {
                    font-size: 14px;
                }

                ul.accepted-cards {
                    li {
                        display: inline-block;

                        img {
                            max-width: 80%;
                        }
                    }
                }
            }
        }

        .about-product {
            margin-top: 40px;
            font-size: 16px;

            h4 {
                margin-bottom: 28px;
                text-align: left;
            }

            .detail-info {
                line-height: 1.9;

                li {
                    font-weight: bold;

                    .country,
                    .evaluation,
                    .compatibility,
                    .variety-mix {
                        font-weight: 400;
                    }
                }
            }

            .call-back {
                margin-top: 22px;
                padding: 16px 18px;
                background-color: $baseBgColorPale;
                font-size: 14px;

                h6 {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    text-align: left;
                }

                .call-wrapper {
                    margin-top: 32px;

                    a {
                        &:last-child {
                            color: $baseColorRed;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    // @include breakpoint(medium) {
    //     .content-wrapper {
    //         .buying-process {
    //             .buy-product {
    //                 button {
    //                     padding-left: 15px;
    //                     padding-right: 15px;
    //                 }
    //             }
    //         }
    //     }
    // }

    @include breakpoint(small) {
        ul.product-presentation {
            li {
                img {}

                &.slick-active {}

                .like {
                    width: 28px;
                    height: 26px;

                    &:before {
                        content: '\f08a';
                        display: block;
                        font-family: 'FontAwesome';
                        font-size: 26px;
                        line-height: 1;
                        color: $baseColorRed;
                    }
                }

                .hot-marker {
                    font-size: 13px;
                    width: 45px;
                    height: 45px;
                }
            }
        }

        .content-wrapper {
            padding-top: 0;

            .rating-stars {
                top: 3px;
            }

            .product-title-wrapper {
                h3 {
                    font-size: 24px;
                }
            }

            .buying-process {
                // margin-top: 88px;

                .buy-product {
                    button {
                        padding-left: 15px;
                        padding-right: 15px;
                        font-size: 14px;
                    }

                    a {
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }

            .about-product {
                margin-top: 30px;

                h4 {
                    margin-bottom: 10px;
                }

                .detail-info {
                    line-height: 1.7;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .back-button {
            display: none;
        }

        .content-wrapper {
            padding-top: 30px;

            .rating-stars {
                position: relative;
                top: 0;
                left: 15px;
                right: auto;
                width: calc(100% - 30px);
                margin-bottom: 10px;
            }

            .product-title-wrapper {
                h3 {
                    font-size: 20px;
                }
            }

            .buying-process {
                text-align: left;
                margin-top: 30px;

                .buy-product {
                    .counter-wrapper {
                        padding-left: 40px;

                        .quantity {
                            &:before {
                                left: 0;
                            }
                        }

                        button {
                            margin-bottom: 0;
                        }
                    }

                    button {
                        // width: 100%;
                        // max-width: 500px;
                        margin-bottom: 20px;
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    a {
                        display: none;
                    }
                }
            }

            .about-product {
                h4 {
                    margin-bottom: 10px;
                }

                .call-back {
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        ul.product-presentation {
            li {
                img {
                    max-width: 42%;
                }

                .like {
                    &:before {
                        font-size: 27px;
                    }

                    &[data-chacked="true"] {
                        &:before {
                            content: '\f004';
                        }
                    }
                }
            }
        }

        .content-wrapper {
            .product-title-wrapper {
                h3 {
                    // display: none;
                    padding-right: 0;
                }

                .short-description {
                    font-size: 14px;
                }
            }

            .product-info {
                .technical-info {
                    margin-bottom: 15px;
                }

                .technical-sheet {
                    li {
                        font-size: 14px;
                    }
                }
            }

            .buying-process {
                margin-top: 25px;

                .buy-product {
                    .counter-wrapper {
                        margin-bottom: 30px;
                    }

                    button {
                        width: 100%;
                    }

                    a {
                        display: none;
                    }

                    p {
                        text-align: center;
                    }

                    ul.accepted-cards {
                        text-align: center;
                    }
                }
            }

            .about-product {
                font-size: 14px;
                margin-top: 30px;

                h4 {
                    margin-bottom: 10px;
                }

                .detail-info {
                    li {
                        font-weight: normal;
                    }
                }

                .call-back {
                    br {
                        display: none;
                    }

                    .call-wrapper {
                        a {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}