.register-account {
    padding-bottom: 50px;

    #content {
        h1 + p {
            text-align: center;
        }

        fieldset {
            margin-top: 25px;

            legend {
                text-align: center;
            }

            .form-group {
                &#custom-field1,
                &#custom-field2,
                &.phone-wrapper,
                &.fax-wrapper,
                &.city-wrapper,
                &.postcode-wrapper,
                &.country-wrapper,
                &.region-wrapper,
                &.password-wrapper,
                &.password-confirm-wrapper {
                    float: left;
                    width: 50%;
                    margin-right: 0;
                    margin-left: 0;

                    .col-sm-10 {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                &#custom-field1,
                &.phone-wrapper,
                &.city-wrapper,
                &.country-wrapper,
                &.password-wrapper {
                    padding-right: 15px;
                }

                &#custom-field2,
                &.fax-wrapper,
                &.postcode-wrapper,
                &.region-wrapper,
                &.password-confirm-wrapper {
                    padding-left: 15px;
                }

                // .select-wrapper {
                //     &:after {
                //         right: 15px;
                //     }
                // }

                &#custom-field4 {
                    margin-bottom: 25px;

                    .customer-photo {
                        display: block;
                        margin: 30px;
                    }

                    .success-upload-photo {
                        display: block;
                        margin-top: 10px;
                        color: green;
                    }
                }

                .control-label {
                    padding-bottom: 5px;
                    line-height: 1;
                    text-transform: uppercase;

                    span {
                        display: inline-block;
                        margin-left: 10px;
                        padding: 1px 2px;
                        font-size: 9px;
                        text-transform: capitalize;
                        vertical-align: top;
                        color: $baseColorRed;
                        border: 1px solid $baseColorRed;
                    }
                }

                &.has-error {
                    .form-control,
                    select {
                        border-color: $baseColorRed;
                    }

                    .select-wrapper {
                        &:after {
                            top: 21px;
                            transform: translateY(0);
                        }

                        &.opened {
                            &:after {
                                transform: translateY(0) rotateZ(180deg);
                            }
                        }
                    }
                }

                .no-valid-regexp,
                .error-message {
                    color: $baseColorRed;
                }
            }

            &#newsletter {
                .form-group {
                    padding-left: 15px !important;
                    padding-right: 15px !important;

                    .radio-wrapper {
                        display: inline-block;
                        margin-left: 30px;

                        label.radio-inline {
                            position: relative;
                            padding-top: 0;
                            padding-left: 25px;
                            line-height: 1;
                            vertical-align: inherit;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            margin-top: 30px;

            .privacy-policy-wrapper {
                display: inline-block;
            }

            [type="checkbox"] + label {
                margin-left: 10px;
            }

            [type="submit"] {
                margin-left: 15px;
            }
        }
    }

    @include breakpoint(small) {
        #content {
            fieldset {
                .form-group {
                    &#custom-field1,
                    &#custom-field2,
                    &.phone-wrapper,
                    &.fax-wrapper,
                    &.city-wrapper,
                    &.postcode-wrapper,
                    &.country-wrapper,
                    &.region-wrapper,
                    &.password-wrapper,
                    &.password-confirm-wrapper {
                        width: auto;
                        min-width: 100%;
                        padding-right: 0;
                        padding-left: 0;

                        // .col-sm-10 {
                        //     padding-right: 0;
                        //     padding-left: 0;
                        // }
                   
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        #content {
            fieldset {
                .form-group {
                    &#custom-field4 {
                        .control-label {
                            text-align: center;
                        }

                        .customer-photo {
                            margin-left: auto;
                            margin-right: auto;
                        }

                        .success-upload-photo {
                            text-align: center;
                        }
                    }
                }

                &#newsletter {
                    text-align: center;
                }
            }

            .buttons {
                padding-left: 0;
                padding-right: 0;
                text-align: center;

                span {
                    display: block;
                }

                [type="submit"] {
                    float: none !important;
                    margin-top: 25px;
                    margin-left: 0;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        #content {
            fieldset {
                &#newsletter {
                    text-align: left;
                }
            }

            .buttons {
                margin-top: 0;
                text-align: left;

                span {
                    font-size: 13px;
                }

                [type="submit"] {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}