.reviews-carousel {
    // padding-left: 0;
    // padding-right: 0;

    .slick-track {
        transition: height 0.5s;
    }

    li {
        padding-right: 15px;
        padding-left: 15px;

        figure {
            position: relative;
            font-weight: 300;
            padding-top: 40px;
            padding-bottom: 35px;
            border: 1px solid #dfdfdf;
            margin-right: 0;
            margin-left: 0;

            .author-photo {
                text-align: center;
                display: inline-block;
                max-height: 86px;
                overflow: hidden;

                img {
                    display: inline-block;
                }
            }

            figcaption {
                .rating-stars {
                    display: inline-block;
                }

                time {
                    float: right;
                    display: inline-block;
                    padding: 0;
                    position: static;
                    background-color: transparent;
                    color: $baseColorRed;
                }

                h5 {
                    margin-top: 18px;
                    margin-bottom: 10px;
                }

                .name {
                    font-weight: bold;
                    font-size: 15px;
                }
            }
        }
    }

    @include breakpoint(medium) {
        padding-left: 0;
        padding-right: 0;

        li {
            figure {
                .author-photo {
                    max-height: 121px;
                }
            }
        }
    }


    @include breakpoint(small) {
        li {
            figure {
                .author-photo {
                    max-height: 84px;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        li {
            figure {
                .author-photo {
                    max-height: 125px;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        li {
            figure {
                .author-photo {
                    max-height: 105px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}