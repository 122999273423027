.reviews-quantity {
    // display: inline-block;
    // background-color: $baseColorRed;
    // padding: 10px 20px;
    // text-transform: uppercase;
    // color: #ffffff;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: none;
    border-radius: 0;
    background-color: #a83252;
    font-family: inherit;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}