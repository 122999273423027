header {
    position: relative;
    padding-top: 20px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 50px;
        background-color: $baseColorRed;
    }

    .header-wrapper {
        position: relative;

        .logo-wrapper {
            float: left;
            width: 255px;
            height: 100px;
            padding-left: 15px;

            img {
                width: 100%;
                height: 72px;
            }
        }

        .contacts-wrapper {
            float: left;
            width: 55%;
            height: 20px;
            display: flex;
            flex-direction: row-reverse;
            font-family: $baseSansSerifFontFamily;
            font-weight: 300;
            line-height: 1;
            color: #625951;

            .phone {
                position: relative;
                top: -5px;
                margin-left: 30px;
                color: #625951;

                &:hover {
                    cursor: pointer;

                    &:before {
                        background-color: #fff;
                        color: $baseColorRed;
                        animation: swing-phone 2s 0.5s infinite;
                    }
                }

                &:before {
                    content: "\f10b";
                    display: inline-block;
                    width: 23px;
                    margin-right: 3px;
                    border-radius: 50%;
                    background-color: $baseColorRed;
                    font-family: 'FontAwesome';
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 23px;
                    text-align: center;
                    color: #fff;
                    transition: all 0.3s;
                }
            }

            @keyframes swing-phone {
                0%, 45% {
                    transform: rotate(0);
                }

                3%, 9%, 15%, 21%, 27%, 33%, 39% {
                    transform: rotate(20deg);
                }

                6%, 12%, 18%, 24%, 30%, 36%, 42% {
                    transform: rotate(-20deg);
                }
            }
        }

        .user-block-wrapper {
            position: relative;
            top: 0;
            float: left;
            width: 90px;
            padding-left: 10px;
            font-family: $baseSansSerifFontFamily;
            font-size: 0;

            .wishlist,
            .basket {
                position: relative;
                display: inline-block;
                width: 50%;
                height: 13px;
                font-size: 11px;
                color: #625951;
                text-align: center;
                text-transform: uppercase;
                white-space: nowrap;

                .wishlist-count,
                .basket-count {
                    position: absolute;
                    top: -9px;
                    right: 0;
                    display: inline-block;
                    min-width: 17px;
                    min-height: 17px;
                    padding: 2px;
                    border-radius: 50%;
                    background-color: #ead4c3;
                    line-height: 13px;
                    color: $baseColorRed;
                }

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%) rotateY(0);
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: all 0.5s;
                }

                &.changed {
                    &:before {
                        transform: translateY(-50%) rotateY(360deg);
                    }
                }
            }

            .wishlist {
                &:before {
                    content: '\f08a';
                    font-family: 'FontAwesome';
                    font-size: 20px;
                    line-height: 1;
                    color: #d05c78;
                }
            }

            .basket {
                &:before {
                    background-image: url("../image/basket.png");
                }
            }
        }

        .main-menu-wrapper {
            float: left;
            width: 100%;
            display: flex;

            .navbar {
                width: 100%;
                margin-bottom: 0;
                border: none;
                background-color: transparent;

                .navbar-header {
                    float: right;
                    width: 240px;

                    .navbar-toggle {
                        float: right;
                        margin: 0;
                        padding-top: 10px;
                        padding-right: 0;
                        padding-bottom: 10px;
                        padding-left: 0;
                        border: none;
                        background-color: transparent;

                        &:hover {
                            background-color: transparent;
                        }

                        span {
                            display: block;
                            height: 4px;
                            background: #000;
                            transform: translateY(-50%);
                            transition: background, 0s, lianer, 0.3s;

                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                display: block;
                                left: 0;
                                width: 100%;
                                height: 4px;
                                background-color: #000;
                                transition-duration: 0.3s, 0.3s;
                                transition-delay: 0.3s, 0s;
                            }

                            &:before {
                                top: -8px;
                                transition-property: top, transform;
                            }

                            &:after {
                                bottom: -8px;
                                transition-property: bottom, transform;
                            }
                        }

                        &.opened {
                            span {
                                background: none;

                                &:before,
                                &:after {
                                    transition-delay: 0s, 0.3s;
                                }

                                &:before {
                                    top: 0;
                                    transform: rotate(45deg);
                                }

                                &:after {
                                    bottom: 0;
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }

                .navbar-collapse {
                    padding-right: 0;
                    padding-left: 0;

                    ul.menu-list {
                        display: flex;
                        justify-content: space-between;
                        font-family: $baseSansSerifFontFamily;
                        margin-bottom: 0;

                        /*padding-left: 20px;
                        padding-right: 20px;*/

                        & > li {
                            text-align: center;
                            position: relative;
                            border: none;

                            &:hover {
                                cursor: pointer;
                                background-color: darken($baseColorRed, 5deg);
                            }

                            & > a {
                                height: 50px;
                                line-height: 50px;
                                padding-right: 15px;
                                padding-left: 15px;
                                border: none;
                                color: #fff;
                                text-transform: capitalize;
                                white-space: nowrap;
                                background-color: $baseColorRed;
                                transition: all 0.25s;

                                &:hover {
                                    color: #fff;
                                    background-color: darken($baseColorRed, 5deg);
                                }

                                &:focus {
                                    outline: none;
                                }

                                & > button {
                                    position: relative;
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    padding: 0;
                                    vertical-align: middle;
                                    background-color: transparent;

                                    &:focus, &:active {
                                        outline: none;

                                        &:after {
                                            color: #fff;
                                        }
                                    }

                                    &:hover {
                                        color: #fff;
                                    }

                                    &:after {
                                        content: "\e259";
                                        font-family: 'Glyphicons Halflings';
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        transform-origin: center;
                                        font-size: 8px;
                                        transition: all 0.3s;
                                    }

                                    &[aria-expanded="true"] {
                                        &:after {
                                            // content: "\e260";
                                            transform: translate(-50%, -50%) rotateZ(180deg);
                                        }
                                    }
                                }
                            }

                            .sub-menu-wrapper {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                z-index: 9;
                                min-width: 100%;
                                background-color: darken($baseColorRed, 5deg);
                                text-align: left;

                                ul.sub-menu {
                                    li {
                                        a {
                                            color: #fff;
                                            padding-top: 5px;
                                            padding-right: 15px;
                                            padding-left: 15px;
                                            padding-bottom: 5px;
                                            min-width: 100%;
                                            white-space: nowrap;
                                            text-transform: capitalize;

                                            // &:hover {
                                            //     background-color: darken($baseColorRed, 5deg);
                                            // }
                                       
                                        }
                                    }
                                }
                            }

                            &.opened {
                                & > a {
                                    background-color: darken($baseColorRed, 5deg);
                                }

                                .sub-menu-wrapper {
                                    ul.sub-menu {
                                        li {
                                            a {
                                                &:hover {
                                                    background-color: darken($baseColorRed, 10deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .account-wrapper {
            position: relative;
            top: -5px;
            float: left;

            .account {
                position: relative;
                margin-left: 20px;
                padding-left: 27px;
                font-size: 11px;
                line-height: 22px;
                height: 22px;
                text-transform: uppercase;
                color: #625951;

                &:hover {
                    cursor: pointer;

                    &:before {
                        background-color: transparent;
                        color: $baseColorRed;

                        // transform: translateY(-50%) rotateY(360deg);
                   
                    }
                }

                &:before {
                    content: "\e008";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%) rotateY(0);
                    display: block;
                    width: 23px;
                    height: 22px;
                    border-radius: 50%;
                    font-family: 'Glyphicons Halflings';
                    line-height: 22px;
                    color: #fff;
                    text-align: center;
                    background-color: $baseColorRed;
                    transition: all 0.3s;
                }
            }
        }

        #search {
            position: relative;
            float: left;
            width: calc(100% - 255px);
            height: 60px;
            padding-top: 20px;

            [type="text"] {
                float: right;
                max-width: 262px;
                height: 40px;
                line-height: 40px;
                padding-top: 0;
                padding-bottom: 0;
                border-right: none;
                border-radius: 0;
                transition: all 0.5s;
                box-shadow: none;
            }

            button {
                width: 40px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                margin: 0;
                border: 1px solid #dfdfdf;
                border-left: none;
                border-radius: 0;
                background-color: transparent;
                color: $baseColorRed;

                &:active {
                    box-shadow: none;
                    color: lighten($baseColorRed, 10deg);
                }

                &:focus {
                    outline: none;
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        #form-language {
            display: none;
        }

        .languages-wrapper {
            position: relative;
            float: right;

            #form-language {
                position: relative;
                display: block;
                line-height: 1;

                .btn-group {
                    button {
                        padding: 0;
                        padding-right: 20px;
                        border: 0;
                        line-height: 1;
                        color: #545454;
                        background-color: transparent;

                        img {
                            width: 16px;
                            height: 11px;
                            vertical-align: baseline;
                        }

                        i {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 12px;
                            line-height: 1;
                            transition: all 0.25s;

                            &:before {
                                line-height: 14px;
                            }
                        }

                        &:focus, &:focus:hover {
                            background-color: transparent;
                            outline: none;
                            text-decoration: none;
                        }
                    }

                    ul.dropdown-menu {
                        top: calc(100% + 5px);
                        right: 0;
                        left: auto;
                        min-width: 135px;
                        z-index: 999;
                        padding: 15px;
                        margin-top: 0;
                        border: 1px solid #dfdfdf;
                        border-radius: 0;
                        box-shadow: none;
                        background-color: #fff;

                        li {
                            button {
                                padding-right: 0;
                                text-align: left;

                                img {
                                    position: relative;
                                    top: 1px;
                                    margin-right: 8px;
                                    width: 16px;
                                    height: 11px;
                                }

                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            &:nth-child(n+2) {
                                margin-top: 15px;
                            }

                            &:hover {
                                button {
                                    color: $baseColorRed;
                                }
                            }
                        }
                    }

                    &.open {
                        button {
                            i {
                                transform: translateY(-50%) rotateZ(180deg);

                                // &:before {
                                // content: "\e260";
                                // }
                           
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {
        .header-wrapper {
            .contacts-wrapper {
                width: 46%;
            }

            .main-menu-wrapper {
                .navbar {
                    .navbar-header {
                        .user-block-wrapper {
                            width: 116px;

                            .wishlist, .basket {
                                &:before {
                                    left: 6px;
                                }

                                span {
                                    right: 10px;
                                }
                            }
                        }
                    }

                    .navbar-collapse {
                        ul.menu-list {
                            & > li {
                                & > a {
                                    padding-right: 9px;
                                    padding-left: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(small) {
        padding-top: 0;

        &:before {
            top: 0;
            bottom: auto;
            height: 25px;
            background-color: #000;
        }

        .header-wrapper {
            padding-top: 55px;

            .logo-wrapper {
                position: relative;
                top: -20px;
                width: 25%;
                height: auto;
                z-index: 3;
            }

            .contacts-wrapper {
                position: relative;
                top: 8px;
                flex-direction: column;
                width: 37%;
                height: auto;
                text-align: right;

                .phone,
                .address {
                    display: block;
                    margin-left: 0;
                }

                .address {
                    margin-top: 5px;
                }
            }

            .user-block-wrapper {
                top: 5px;
            }

            .main-menu-wrapper {
                position: absolute;
                top: 60px;
                z-index: 2;

                .navbar {
                    .navbar-header {
                        width: auto;
                        float: right;

                        .navbar-toggle {
                            display: block;
                            margin-left: 20px;
                            margin-right: 25px;
                        }

                        .user-block-wrapper {
                            padding-left: 20px;
                        }

                        .account-wrapper {
                            width: 25px;
                            height: 25px;
                            margin-left: 25px;

                            .account {
                                margin-left: 0;
                                padding-left: 0;

                                span {
                                    display: none;
                                }
                            }
                        }

                        #search {
                            top: -8px;
                            width: 40px;
                            height: 38px;
                            padding-top: 0;
                            margin-left: 5px;

                            [type="text"] {
                                position: absolute;
                                right: 40px;
                                float: none;
                                width: 0;
                                max-width: 280px;
                                padding-left: 0;
                                padding-right: 0;
                                border-color: transparent;
                                background-color: #fff;
                                z-index: 0;
                            }

                            .input-group-btn {
                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            button {
                                top: 0;
                                border-color: transparent;
                                font-size: 20px;
                                transition-delay: 0.3s;
                            }

                            &.search-active {
                                [type="text"] {
                                    width: 280px;
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    border-color: #dfdfdf;
                                    border-right-color: transparent;
                                }

                                button {
                                    transition-delay: 0s;

                                    // border-color: #dfdfdf;
                                    color: #fff;
                                    background-color: $baseColorRed;
                                    border-color: $baseColorRed;
                                }
                            }
                        }
                    }

                    .navbar-collapse {
                        width: 100%;

                        &.collapse {
                            display: none !important;
                        }

                        &.in {
                            display: block !important;
                        }

                        ul.menu-list {
                            flex-direction: column;
                            width: 100%;
                            z-index: 999;
                            background-color: $baseColorRed;
                            padding-left: 0;
                            padding-right: 0;

                            li {
                                a {
                                    width: 100%;

                                    button {
                                        position: absolute;
                                        top: 20px;
                                        right: 20px;
                                    }
                                }

                                .sub-menu-wrapper {
                                    position: relative;
                                    top: 0;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            .account-wrapper {
                top: 0;
            }

            .languages-wrapper {
                position: absolute;
                top: 4px;
                right: 10px;

                #form-language {
                    .btn-group {
                        & > button {
                            color: #fff;
                        }
                    }
                }
            }

            #search {
                button {
                    z-index: -1;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .header-wrapper {
            .logo-wrapper {
                top: -10px;
                width: 100%;
                height: 75px;
                padding-left: 0;
                text-align: center;

                .logo {
                    img {
                        max-width: 200px;
                    }
                }
            }

            .contacts-wrapper {
                display: none;
            }

            .main-menu-wrapper {
                position: relative;
                top: 0;

                .navbar {
                    min-height: auto;
                    text-align: center;

                    .navbar-header {
                        float: none;
                        display: inline-block;
                        width: auto;
                        padding-left: 15px;

                        .navbar-toggle {
                            float: left;
                        }

                        .account-wrapper {
                            margin-left: 5px;
                            text-align: left;
                        }

                        #search {
                            &.search-active {
                                [type="text"] {
                                    max-width: 215px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}