$baseSansSerifFontFamily: "Proxima Nova", sans-serif;
$baseSerifFontFamily: "Century751 No2", serif;
$baseColorRed: #a83252;
$textColorGrey: #545454;
$textColorBrown: #827870;
$baseBgColorPale: #fff6ef;
$baseBorderColor: #dfdfdf;

html {
    body {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        font-family: $baseSansSerifFontFamily;
        font-size: 14px;
        line-height: 1.7;

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
            line-height: 1.4;
            text-align: center;
        }

        h1 {
            font-family: $baseSerifFontFamily;
            font-size: 48px;
            font-weight: 400;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 45px;
            }


            @include breakpoint(small) {
                font-size: 38px;
            }


            @include breakpoint(extra-small) {
                font-size: 30px;
            }


            @include breakpoint(mobile) {
                font-size: 22px;
            }
        }

        h2 {
            font-family: $baseSerifFontFamily;
            font-size: 32px;
            font-weight: 400;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 26px;
            }


            @include breakpoint(small) {
                font-size: 18px;
            }


            @include breakpoint(extra-small) {
                font-size: 24px;
            }


            @include breakpoint(mobile) {
                font-size: 20px;
            }
        }

        h3 {
            font-family: $baseSansSerifFontFamily;
            font-size: 24px;
            font-weight: 700;

            @include breakpoint(medium) {
                font-size: 22px;
            }


            @include breakpoint(small) {
                font-size: 20px;
            }
        }

        h4 {
            font-family: $baseSansSerifFontFamily;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }

        h5 {
            font-family: $baseSansSerifFontFamily;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 13px;
            }


            @include breakpoint(small) {
                font-size: 14px;
            }


            @include breakpoint(extra-small) {
                text-align: center;
            }
        }

        h6 {
            font-family: $baseSansSerifFontFamily;
            font-size: 14px;
            text-transform: uppercase;
        }

        .custom-modal-title {
            font-weight: 600;
            text-transform: initial;
        }

        img {
            display: inline-block;
            max-width: 100%;
        }

        a {
            display: inline-block;
            text-decoration: none;
            outline: none !important;
            color: #000;
            transition: all 0.3s;

            &::-moz-focus-inner {
                border: 0 !important;
            }

            &:hover, &:focus {
                text-decoration: none;
                color: #000;
            }
        }

        ul {
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
        }

        .tooltip {
            display: none !important;
        }

        .clear-fix {
            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }
    }
}