.best-deals {
    padding-top: 100px;
    padding-bottom: 70px;
    background-color: #fff;

    ul.best-deals-list {
        position: relative;
        margin-top: 100px;

        li {
            padding-left: 15px;
            padding-right: 15px;

            .item-wrapper {
                border: 1px solid #dfdfdf;

                .product-info-wrapper {
                    position: relative;
                    padding: 20px;

                    .hot {
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        display: block;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: $baseColorRed;
                        font-size: 14px;
                        line-height: 50px;
                        font-weight: 700;
                        text-transform: uppercase;
                        text-align: center;
                        color: #fff;
                        z-index: 1;
                    }

                    .product-image-wrapper {
                        position: relative;
                        height: 350px;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: contain;

                        // img {
                        //     position: absolute;
                        //     top: 50%;
                        //     left: 50%;
                        //     transform: translate(-50%, -50%);
                        //     min-height: 90%;
                        //     max-height: 90%;
                        // }
                   
                    }

                    .price {
                        min-height: 38px;
                        margin-top: 15px;

                        .new-price {
                            display: inline-block;
                            padding: 10px 15px;
                            background-color: #000;
                            font-size: 18px;
                            line-height: 1;
                            font-weight: 700;
                            color: #fff;

                            .currency {
                                font-weight: 300;
                                font-style: normal;
                            }
                        }

                        .old-price {
                            display: inline-block;
                            margin-left: 15px;
                            font-size: 13px;
                            line-height: 1;
                            font-weight: 300;
                            text-decoration: line-through;

                            .currency {
                                font-style: normal;
                                text-decoration: none;
                            }
                        }
                    }

                    h5 {
                        min-height: 44px;
                        margin-top: 25px;
                        margin-bottom: 0;
                        color: #000;
                    }

                    ul.features {
                        margin-top: 25px;
                        color: $textColorGrey;

                        & > li {
                            font-weight: 300;
                            padding-left: 0;
                            padding-right: 0;

                            span {
                                font-weight: 700;
                            }

                            // &:nth-child(n+4) {
                            //     display: none;
                            // }
                       
                        }
                    }
                }

                .more-info-wrapper {
                    position: relative;
                    border-top: 1px solid #dfdfdf;

                    .info {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: inline-block;
                        padding-left: 52px;
                        line-height: 1;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                            display: block;
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                            background-color: #ead4c3;
                            background-image: url("../image/icon-bottle.png");
                            background-repeat: no-repeat;
                            background-position: center;
                        }

                        .info-value {
                            color: $baseColorRed;
                        }
                    }

                    .button {
                        position: relative;
                        float: right;
                        padding-top: 15px;
                        padding-left: 35px;
                        padding-right: 15px;
                        padding-bottom: 17px;
                        font-size: 18px;
                        text-transform: capitalize;

                        .glyphicon {
                            margin-left: 10px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {
        padding-top: 70px;
        padding-bottom: 80px;

        ul.best-deals-list {
            margin-top: 70px;
            padding-left: 0;
            padding-right: 0;
        }
    }


    // @include breakpoint(small) {
    //     ul.best-deals-list {
    //         padding-left: 0;
    //         padding-right: 0;
    //     }
    // }

    @include breakpoint(extra-small) {
        padding-top: 50px;

        ul.best-deals-list {
            margin-top: 50px;

            li {
                .item-wrapper {
                    .product-info-wrapper {
                        .hot {
                            width: 45px;
                            height: 45px;
                            font-size: 12px;
                            line-height: 45px;
                        }

                        .product-image-wrapper {
                            height: 280px;
                        }

                        .price {
                            min-height: 36px;
                            margin-top: 25px;

                            .new-price {
                                font-size: 16px;
                            }
                        }
                    }

                    .more-info-wrapper {
                        .info {
                            max-width: 50%;
                        }
                    }
                }
            }
        }
    }
}