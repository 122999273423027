.added-info-wrapper {
    .social-sharing-wrapper {
        margin-top: 30px;

        .share {
            &:before {
                color: $baseColorRed !important;
            }
        }

        .social-sharing-buttons, .show-social-button {
            .addthis_inline_share_toolbox {
                .at-share-btn-elements {
                    .at-share-btn {
                        padding: 0 50px;
                        margin-right: 30px;

                        &:hover {
                            transform: translateY(0);
                            border-radius: 15px 0 !important;
                        }

                        &:focus {
                            transform: translateY(0);
                        }
                    }

                    .at-icon-wrapper {
                        font-size: 30px;

                        &:last-child {
                            margin-right: 0px;
                        }

                        .at-label {
                            padding: 0 !important;
                            text-align: center !important;
                            font-size: 18px !important;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(small) {
        .social-sharing-wrapper {
            margin-top: 70px;

            .share {
                margin-top: -50px;
                display: block;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }

            .social-sharing-buttons, .show-social-button {
                .addthis_inline_share_toolbox {
                    .at-share-btn-elements {
                        .at-share-btn {}

                        .at-icon-wrapper {
                            .at-label {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        text-align: center !important;

        .social-sharing-wrapper {
            margin-top: 80px;

            .share {
                margin-top: -50px;
                display: block;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }

            .social-sharing-buttons, .show-social-button {
                .addthis_inline_share_toolbox {
                    .at-share-btn-elements {
                        .at-share-btn {
                            padding-right: 10px;
                            padding-left: 10px;
                            width: auto;
                            max-width: 135px;
                            margin-right: 15px;
                            margin-left: 15px;

                            &:nth-of-type(n+2) {
                                margin-left: 10px;
                            }
                        }

                        .at-icon-wrapper {
                            .at-label {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .social-sharing-wrapper {
            .social-sharing-buttons, .show-social-button {
                .addthis_inline_share_toolbox {
                    .at-share-btn-elements {
                        .at-share-btn {
                            max-width: none;

                            &:nth-of-type(n) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}