body.extension-module-oneall-register {
    #content {
        padding-bottom: 50px;

        form {
            .form-group {
                &#custom-field1,
                &#custom-field2,
                &.phone-wrapper,
                &.fax-wrapper {
                    float: left;
                    width: 50%;
                    margin-right: 0;
                    margin-left: 0;
                }

                &#custom-field1,
                &.phone-wrapper {
                    .col-sm-10 {
                        padding-left: 0;
                    }
                }

                &#custom-field2,
                &.fax-wrapper {
                    .col-sm-10 {
                        padding-right: 0;
                    }
                }

                &#custom-field4 {
                    margin-bottom: 25px;

                    .customer-photo {
                        display: block;
                        width: 100%;
                        max-width: 250px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-left: 0;
                    }

                    .success-upload-photo {
                        display: block;
                        margin-top: 10px;
                        color: green;
                    }
                }

                .no-valid-regexp,
                .error-message {
                    color: $baseColorRed;
                }
            }

            #newsletter {
                .form-group {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .radio-wrapper {
                    display: inline-block;
                    margin-left: 30px;

                    label.radio-inline {
                        position: relative;
                        padding-top: 0;
                        padding-left: 25px;
                        line-height: 1;
                        vertical-align: inherit;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(small) {
        #content {
            form {
                .form-group {
                    &#custom-field1,
                    &#custom-field2,
                    &.phone-wrapper,
                    &.fax-wrapper {
                        width: auto;
                        min-width: 100%;

                        .col-sm-10 {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        #content {
            form {
                #newsletter {
                    text-align: center;
                }

                .buttons {
                    text-align: center;

                    .pull-right {
                        float: none !important;
                        margin-top: 25px;
                        margin-left: 0;
                    }
                }
            }
        }
    }


    @include breakpoint(mobile) {
        #content {
            form {
                #newsletter {
                    text-align: left;
                }

                .buttons {
                    margin-top: 0;
                    text-align: left;

                    [type="submit"] {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}