.cart {
    padding-bottom: 100px;

    // button, .button, .btn, input[type="submit"] {
    //     &:focus {
    //         background-color: $baseColorRed;
    //         color: #ffffff;
    //     }
    // }

    p {
        text-align: center;
    }

    form {
        table {
            tbody {
                tr {
                    td {
                        // padding-left: 15px;
                        // padding-right: 15px;

                        &.product-title {
                            a {
                                min-height: 40px;
                                text-transform: uppercase;
                            }
                        }

                        &.product-quantity {
                            width: 195px;

                            .quantity-minus,
                            .quantity-plus {
                                float: left;
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                            }

                            [type="text"] {
                                width: 47px;
                                height: 20px;
                                line-height: 20px;
                                margin-left: 5px;
                                margin-right: 5px;
                                padding: 0px 10px;
                                font-size: 14px;
                            }

                            .input-group-btn {
                                display: inline-block;
                                width: auto;
                                margin-left: 30px;

                                [data-original-title="Update"],
                                [data-original-title="Remove"] {
                                    background-color: transparent;
                                    color: $baseColorRed;
                                    transition-duration: 0s;

                                    &:nth-child(n+2) {
                                        margin-left: 10px;
                                    }

                                    i {
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    h2 {
        margin-top: 15px;
    }

    #accordion {
        margin-top: 25px;

        .panel {
            .panel-heading {
                .panel-title {
                    a {
                        i {
                            margin-left: 5px;
                            font-size: 14px;
                            transition: all 0.25s;

                            &:before {
                                content: '\e259';
                                font-family: 'Glyphicons Halflings';
                            }
                        }

                        &[aria-expanded="true"] {
                            i {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .panel-collapse {
                .panel-body {
                    .control-label {
                        float: none;
                        width: 100%;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                        text-align: left;
                    }

                    .input-group {
                        input {
                            line-height: 16px;
                        }

                        .input-group-btn {
                            [type="button"], [type="submit"] {
                                padding-top: 19px;
                                padding-bottom: 19px;
                            }
                        }
                    }

                    .select-wrapper {
                        width: 100%;

                        &:after {
                            z-index: 0;
                            right: 30px;
                        }
                    }

                    .form-group {
                        .col-sm-10 {
                            width: 100%;
                        }
                    }

                    [type="button"] {
                        padding-top: 19px;
                        padding-bottom: 19px;
                    }
                }
            }
        }
    }

    @include breakpoint(extra-small) {
        padding-bottom: 60px;

        form {
            table {
                tbody {
                    tr {
                        td {
                            .input-group {
                                width: 165px;

                                .input-group-btn {
                                    margin-left: 15px;

                                    [data-original-title="Update"],
                                    [data-original-title="Remove"] {
                                        &:nth-child(n+2) {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        h2 {
            font-size: 18px;
        }

        #accordion {
            .panel {
                .panel-heading {
                    .panel-title {
                        a {
                            font-size: 14px;

                            i {
                                font-size: 10px;
                            }
                        }
                    }
                }

                .panel-collapse {
                    .panel-body {
                        .control-label {
                            font-size: 13px;
                            text-align: center;
                        }

                        .input-group {
                            width: 100%;

                            input {
                                display: block;
                                float: none;
                            }

                            .input-group-btn {
                                display: block;
                                width: auto;
                                margin-top: 15px;

                                [type="button"], [type="submit"] {
                                    width: auto;
                                    margin-left: 0;
                                }
                            }
                        }

                        [type="button"] {
                            width: auto;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(mobile) {
        #accordion {
            .panel {
                .panel-collapse {
                    .panel-body {
                        .input-group {
                            .input-group-btn {
                                [type="button"], [type="submit"] {
                                    width: 100%;

                                    // padding-top: 15px;
                                    // padding-bottom: 15px;
                               
                                }
                            }
                        }

                        [type="button"] {
                            width: 100%;

                            // padding-top: 15px;
                            // padding-bottom: 15px;
                       
                        }
                    }
                }
            }
        }

        .buttons {
            margin-top: 25px;

            & > div {
                float: none !important;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }

                a {
                    width: 100%;
                }
            }
        }
    }
}