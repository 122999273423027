ul.pagination {
    float: right;
    margin-top: 0;
    margin-bottom: 0;

    li {
        display: inline-block;
        text-align: center;

        &:nth-child(n+2) {
            margin-left: 6px;
        }

        &:first-child, &:last-child {
            a {
                border-radius: 0;
            }
        }

        span, a, a:focus {
            border: 1px solid $baseBorderColor;
            display: block;
            width: 30px;
            height: 30px;
            padding: 0;
            line-height: 30px;
            border-radius: 0;
            color: #848484;
            background-color: transparent;
        }

        &.active {
            span, span:hover {
                border-radius: 0;
                background-color: $baseBgColorPale;
                border-color: $baseBorderColor;
                color: #848484;
            }
        }
    }

    @include breakpoint(extra-small) {
        float: none;
    }
}