.faq {
    padding-top: 30px;
    padding-bottom: 70px;

    .faq-list {
        display: flex;
        flex-justify: space-between;
        flex-wrap: wrap;

        li {
            position: relative;
            margin-top: 40px;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 20px;
            padding-right: 65px;
            color: $textColorBrown;
            font-weight: 300;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                display: block;
                width: 70px;
                height: 190px;
                background-color: $baseBgColorPale;
            }

            h5 {
                margin-bottom: 25px;
                color: #000;
            }

            .faq-answer-content-wrapper {
                position: relative;

                .faq-answer-content {
                    height: 0;
                    overflow: hidden;
                    transition: all 0.4s;
                }
            }

            .view-more {
                position: absolute;
                bottom: -25px;
                padding: 0;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                color: #a83252;
                background-color: transparent;
            }
        }
    }

    @include breakpoint(medium) {
        padding-top: 50px;
        padding-bottom: 120px;
    }


    @include breakpoint(small) {
        .faq-list {
            li {
                margin-top: 20px;
                padding-right: 20px;

                h5 {
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .faq-list {
            li {
                padding-top: 20px;

                &:before {
                    height: 110px;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .faq-list {
            padding-left: 15px;
            padding-right: 15px;

            li {
                &:before {
                    height: 170px;
                }
            }
        }
    }


    @include breakpoint(mobile-landscape) {
        .faq-list {
            li {
                &:before {
                    height: 110px;
                }
            }
        }
    }
}