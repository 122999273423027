body[class^="product-category-"] {
    .subscribe {
        margin-top: 40px;
        padding-bottom: 0;
    }

    .customers-reviews {
        padding-top: 0;
        padding-bottom: 30px;
        margin-top: 70px;
        margin-bottom: 80px;
    }

    .product-description {
        .content-wrapper {
            .buying-process {
                button {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    @include breakpoint(small) {
        .customers-reviews {
            margin-top: 40px;
        }
    }


    @include breakpoint(mobile) {
        .product-description {
            .content-wrapper {
                .buying-process {
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}