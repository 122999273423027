footer {
    position: relative;
    padding-top: 27px;
    padding-bottom: 32px;
    background-color: #000000;
    color: #ffffff;
    line-height: 1.7;
    font-weight: 300;
    text-align: left;

    .follow-us {
        position: static;
        font-size: 11px;
        color: #9e9d9d;

        .logo {
            max-width: 220px;
            margin-bottom: 39px;
        }

        h5 {
            color: #ffffff;
        }

        ul.social-icons {}
    }

    .support-info {
        color: #9d9d9d;

        h5 {
            margin-top: 26px;
            margin-bottom: 21px;
            color: #fff;
        }

        .contact-info {
            font-size: 13px;
            margin-bottom: 22px;

            p {
                margin: 0;
                line-height: 1;

                &:nth-child(1), &:nth-child(2) {
                    &:before {
                        font-family: 'FontAwesome';
                        color: $baseColorRed;
                        font-size: 17px;
                        font-weight: bold;
                    }
                }

                &:nth-child(1) {
                    &:before {
                        content: "\f10b";
                        margin-right: 17px;
                        font-size: 23px;
                    }
                }

                &:nth-child(2) {
                    &:before {
                        content: '\f003';
                        margin-right: 11px;
                    }
                }

                a {
                    display: inline;
                    line-height: 1.2;
                    color: #9d9d9d;

                    &:hover, &:focus {
                        color: #9d9d9d;
                    }
                }
            }
        }

        .address-info {
            margin-left: 28px;
            line-height: 1.5;
            font-size: 13px;

            .detail-address {
                &:before {
                    content: '\f041';
                    font-family: 'FontAwesome';
                    margin-left: -28px;
                    margin-right: 17px;
                    font-size: 17px;
                    font-weight: bold;
                    color: $baseColorRed;
                }
            }
        }
    }

    .customer-info {
        text-align: left;
        padding-left: 80px;

        @include breakpoint(large) {
            padding-left: 0;
            order: 1;
        }


        h5 {
            margin-top: 26px;
            margin-bottom: 14px;
        }

        .accepted-cards {
            margin-bottom: 35px;
            text-align: left;

            ul.cards {
                li {
                    display: inline-block;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    img {
                        filter: grayscale(100%);
                    }
                }
            }
        }

        ul.detail-customer-info {
            color: #9d9d9d;
            font-size: 13px;
            line-height: 1.6;

            li {
                a {
                    color: #9d9d9d;

                    &:hover, &:focus {
                        color: #9d9d9d;
                    }
                }
            }
        }
    }

    .copyright-wrapper {
        text-align: center;

        span {
            display: inline-block;
            margin-top: 15px;
            font-size: 10px;

            a {
                width: 100px;
                margin-left: 5px;
                line-height: 1;
                vertical-align: text-bottom;
            }
        }
    }

    @include breakpoint(small) {
        .follow-us {
            .logo {
                margin-bottom: 20px;
            }

            .social-icons {}
        }

        .support-info {
            .contact-info {}

            .address-info {}
        }

        .customer-info {
            .accepted-cards {
                margin-bottom: 26px;

                ul.cards {
                    li {
                        img {
                            max-width: 85%;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .flex-wrapper {
            display: flex;
            flex-direction: column;

            .follow-us {
                text-align: center;
                order: 3;

                .logo {
                    position: absolute;
                    top: 25px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .social-icons {}
            }

            .support-info {
                margin-top: 65px;
                text-align: center;
                order: 1;

                .contact-info {}

                .address-info {}
            }

            .customer-info {
                .accepted-cards {
                    text-align: center;

                    ul.cards {
                        li {
                            img {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }

                ul.detail-customer-info {
                    margin-bottom: 20px;
                    text-align: center;
                }
            }
        }
    }
}