﻿button, .button, .btn, input[type="submit"] {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: none;
    border-radius: 0;
    background-color: $baseColorRed;
    font-family: inherit;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: #fff;
    cursor: pointer;
    appearance: none;
    box-shadow: none;
    user-select: none;
    transition: all 0.25s;

    &:hover {
        outline: none;
        color: #fff;
        background-color: darken($baseColorRed, 10deg);
    }

    &:focus {
        outline: none !important;
        box-shadow: none;
        color: #fff;
        background-color: $baseColorRed;

        &:hover {
            background-color: darken($baseColorRed, 10deg);
        }
    }

    &:active {
        outline: none !important;
        box-shadow: none;
        color: #fff;
        background-color: darken($baseColorRed, 10deg);
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        opacity: 1;

        // background-color: darken($baseColorRed, 10deg);
   
    }
}