body[class="account-order"] {
    #content {
        table {
            tbody {
                tr {
                    td {
                        &:last-child {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}