.bootstrap-datetimepicker-widget {
    border-radius: 0px;
    box-shadow: none;

    thead, tbody, tfoot {
        tr {
            td {
                border: none !important;
            }
        }
    }

    thead {
        background-color: transparent;
    }

    &:before {
        border-bottom-color: $baseBorderColor !important;
    }

    &:before, &:after {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }

    .list-unstyled {
        .datepicker {
            .picker-switch {
                font-size: 20px;
            }

            .prev,
            .next {
                font-size: 20px;
                color: $baseColorRed;
            }

            .prev,
            .next,
            .picker-switch {
                &:hover {
                    background-color: transparent;
                }
            }

            .day {
                transition: all 0.25s;

                &:before {
                    display: none;
                }

                &:hover {
                    background-color: $baseBgColorPale;
                    border-radius: 0;
                }

                &.active {
                    background-color: $baseColorRed;
                    border-radius: 0;
                }
            }
        }

        .picker-switch {
            td {
                a {
                    padding: 0;

                    span {
                        font-size: 16px;
                        color: $baseColorRed;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

                &:first-of-type {
                    padding-left: 10px;
                    text-align: left;
                }

                &:last-of-type {
                    padding-right: 12px;
                    text-align: right;
                }
            }
        }
    }
}