.breadcrumb-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;

    ul.breadcrumb {
        position: relative;
        display: inline-block;
        width: auto;
        max-width: calc(100% - 50px);
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        border-radius: 0;
        line-height: 40px;
        white-space: nowrap;
        overflow: hidden;
        background-color: transparent;

        li {
            display: inline-block;

            & + li:before {
                content: '|';
                padding-right: 10px;
                color: $textColorGrey;
            }

            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            &:last-child {
                a {
                    color: $baseColorRed;
                }
            }

            a {
                line-height: 1;
            }
        }
    }

    .back {
        float: right;
        display: block;
        width: 20px;
        height: 40px;
        padding: 0;
        background-image: url("../image/back-btn.png");
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 50%;

        &:hover, &:focus {
            background-color: transparent;
        }

        // &:hover {
        //     transform: translateX(-5px);
        // }
   
    }

    @include breakpoint(small) {
        margin-top: 0;
        border-top: 1px solid $baseBorderColor;
        border-bottom: 1px solid $baseBorderColor;

        .back {
            margin-right: 10px;
        }
    }


    @include breakpoint(extra-small) {
        ul.breadcrumb {
            max-width: calc(100% - 15px);
        }

        .back {
            display: none;
        }
    }


    @include breakpoint(mobile) {
        ul.breadcrumb {
            font-size: 11px;
        }
    }

    @media (max-width: 370px) {
        ul.breadcrumb {
            text-align: center;
            white-space: normal;
        }
    }
}