body[class^="account-"] {
    .account-info {
        padding-bottom: 50px;

        // [type="text"], select {
        //     height: 54px;
        // }

        .counter-wrapper {
            [type="text"] {
                width: 55px;
                height: 26px;
                line-height: 26px;
                margin-left: 5px;
                margin-right: 5px;
                padding: 0px 10px;
                font-size: 14px;
            }
        }

        .text-right {
            width: 100%;
        }
    }

    #column-right {
        .show-menu {
            display: none;
        }

        .list-group {
            margin-bottom: 0;
            overflow: hidden;
            transition: all 0.5s;

            a {
                border: none;
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 20px;
                font-size: 16px;
                color: #000;

                &:nth-of-type(n+2) {
                    margin-top: 10px;
                }

                &:hover {
                    color: $baseColorRed;

                    &:before {
                        left: 5px;
                    }
                }

                &:before {
                    content: "\e080";
                    font-family: 'Glyphicons Halflings';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    transform-origin: center;
                    font-size: 10px;
                    transition: all 0.3s;
                }

                &:last-of-type {
                    &:before {
                        content: "\f08b";
                        font-family: 'FontAwesome';
                        font-size: 14px;
                    }
                }
            }
        }
    }

    #content {
        h1 {
            margin-bottom: 30px;
        }

        h2 {
            text-align: left;

            &:nth-of-type(n+2) {
                margin-top: 30px;
            }
        }

        .list-unstyled {
            li {
                a {
                    &:hover {
                        color: $baseColorRed;
                    }
                }
            }
        }

        .buttons {
            margin-top: 25px;

            [type="button"], [type="submit"], .btn {
                &:active, &:hover {
                    background-color: #81263f;
                    border-color: #81263f;
                    color: #fff;
                }
            }
        }

        // .select-wrapper {
        //     &:after {
        //         right: 30px;
        //     }
        // }

        #newsletter {
            .form-group {
                padding-left: 15px;
            }
        }
    }

    @include breakpoint(small) {
        #content {
            #newsletter {
                .form-group {
                    padding-left: 0;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        #column-right {
            float: none;

            .show-menu {
                display: block;
                width: 100%;
                background-color: #000;
                font-size: 13px;
                color: #fff;
                text-transform: uppercase;

                i {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    transition: all 0.3s;
                }
            }

            &.full-list {
                .show-menu {
                    i {
                        transform: translateY(-50%) rotateZ(-180deg);
                    }
                }
            }

            .list-group {
                a {
                    padding-top: 10px;
                    padding-left: 0;
                    text-align: center;

                    &:nth-of-type(n+2) {
                        margin-top: 0;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }

        #content {
            margin-top: 30px;
            text-align: center;

            h1 {
                margin-bottom: 10px;
            }

            h2 {
                text-align: center;
            }

            .list-unstyled {
                li {
                    a {
                        padding-top: 10px;
                    }
                }
            }

            .buttons {
                & > div {
                    float: none !important;

                    &:nth-child(n+2) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

body[class^="account-login"],
body[class^="account-forgotten"],
body[class^="account-register"],
body[class^="account-logout"] {
    #column-right {
        .list-group {
            a {
                &:last-of-type {
                    &:before {
                        content: "\e080";
                        font-family: 'Glyphicons Halflings';
                        font-size: 10px;
                    }
                }
            }
        }
    }
}