.contact-us {
    .content-wrapper {
        h1 {
            margin-bottom: 60px;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            color: $textColorGrey;
        }
    }

    .contact-info-wrapper {
        margin-top: 50px;
        font-size: 18px;
        font-weight: 300;
        color: $textColorGrey;

        address {
            line-height: 1.4;

            span {
                font-weight: 700;
            }
        }

        label {
            display: block;
            margin-bottom: 0;
            line-height: 1.4;

            a {
                font-weight: 300;
            }
        }
    }

    .contact-form-wrapper {
        text-align: center;

        .contact-us-button {
            margin-top: 70px;
        }

        .modal {
            h3 {
                margin-bottom: 0;
            }
        }
    }

    #map {
        height: 550px;
        margin-top: 75px;
    }

    @include breakpoint(leptop) {
        #map {
            height: 500px;
        }
    }


    @include breakpoint(medium) {
        #map {
            height: 400px;
        }
    }


    @include breakpoint(small) {
        padding-top: 20px;

        .content-wrapper {
            h1 {
                margin-bottom: 30px;
            }
        }

        .contact-info-wrapper {
            margin-top: 20px;
        }

        .contact-form-wrapper {
            .contact-us-button {
                margin-top: 50px;
            }
        }

        #map {
            height: 400px;
            margin-top: 50px;
        }
    }


    @include breakpoint(extra-small) {
        .contact-info-wrapper {
            text-align: center;
        }
    }
}