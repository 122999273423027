.short-experiense-info {
    background-color: $baseBgColorPale;
    padding-top: 40px;
    padding-bottom: 40px;

    .short-info {
        figure {
            position: relative;

            .icon {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                height: 48px;
                width: 48px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            figcaption {
                display: inline-block;
                padding-left: 65px;

                h5 {
                    text-transform: initial;
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint(small) {
            margin-top: 40px;
            text-align: center;

            &:nth-child(3n) {
                clear: left;
            }

            &:nth-child(1) {
                margin-top: 0;
            }

            &:nth-child(2) {
                margin-top: 0;
            }

            figure {
                .icon {
                    height: 35px;
                    width: 35px;
                    position: static;
                    transform: none;
                }

                figcaption {
                    display: block;
                    padding-left: 0;

                    h5 {
                        text-align: center;
                    }
                }
            }
        }
    }
}