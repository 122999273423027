.product-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;

    .product-card {
        margin-right: 15px;
        margin-left: 15px;
    }
}