.classification-booze {
    padding-top: 90px;
    padding-bottom: 85px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;

    h2 {
        margin-bottom: 70px;
    }

    ul {
        li {
            figure {
                position: relative;
                margin-top: 30px;
                margin-right: 0;
                margin-left: 0;
                padding: 30px 15px;
                background-color: #ffffff;

                .booze-picture {
                    text-align: center;

                    img {}
                }

                figcaption {
                    font-weight: 100;

                    a {
                        h4 {
                            text-align: left;
                            margin-bottom: 25px;
                        }

                        &:hover {
                            color: $baseColorRed;
                        }
                    }
                }

                .button {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding: 10px 10px;
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    opacity: 0.2;
                    transition: all 0.5s;

                    &:before {
                        content: '\e800';
                        display: inline-block;
                        font-family: "fontello";
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include breakpoint(extra-small) {
        padding-top: 45px;
        padding-bottom: 70px;

        h2 {
            margin-bottom: 0;
        }

        ul {
            li {
                figure {
                    .booze-picture {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}