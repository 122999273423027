.our-news {
    padding-top: 110px;
    padding-bottom: 55px;

    h2 {
        margin-bottom: 110px;
    }

    ul.news-slider {
        li {
            padding-right: 15px;
            padding-left: 15px;

            figure {
                background-color: $baseBgColorPale;

                .picture-news {
                    position: relative;
                    min-height: 250px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                .inner-news-wrapper {
                    padding-top: 30px;
                    padding-right: 20px;
                    padding-bottom: 15px;
                    padding-left: 20px;
                    font-weight: 300;

                    h5 {
                        margin-bottom: 30px;
                        text-align: left;
                    }

                    p {}
                }
            }
        }
    }

    @include breakpoint(medium) {
        ul.news-slider {
            padding-right: 0;
            padding-left: 0;
        }
    }


    @include breakpoint(extra-small) {
        padding-top: 70px;

        h2 {
            margin-bottom: 55px;
        }

        ul.news-slider {
            li {
                figure {
                    .picture-news {
                        min-height: 160px;

                        time {
                            span {}
                        }
                    }

                    .inner-news-wrapper {}
                }
            }
        }
    }
}