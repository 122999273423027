.individual-product-page-layout {
    .product-description {
        margin-top: 20px;

        &.sold {
            .content-wrapper {
                .product-info {
                    .sold-product {
                        display: inline-block;
                        margin-bottom: 30px;
                        font-size: 24px;
                        color: $baseColorRed;
                        font-weight: 700;
                    }
                }

                .buying-process {
                    .buy-product {
                        button {
                            margin-bottom: 50px;
                            background-color: #ccc;
                            cursor: not-allowed;

                            & + a {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .products-carousel {
        padding-top: 15px;

        h2 {
            font-size: 30px;
            margin-bottom: 50px;
        }

        ul.products-list {
            padding-left: 0;
            padding-right: 0;

            .custom-slide-wrapper {
                padding-right: 15px;
                padding-left: 15px;

                li {
                    @extend .clear-fix;

                    width: 100%;
                    margin-bottom: 0;

                    ul.features {
                        .custom-slide-wrapper {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }

                    .purchase-wrapper {
                        .button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .customers-reviews {
        margin-top: 30px;
        padding-top: 0;
        padding-bottom: 120px;

        .title-review {
            margin-bottom: 25px;

            .title-wrapper {
                h3 {
                    margin-bottom: 0;
                    line-height: 1;
                }
            }

            .reviews-counter-wrapper {
                .button {
                    font-size: 24px;
                    padding: 0;
                    background-color: transparent;
                    color: $baseColorRed;
                }
            }
        }

        .write-view-button-wrapper {
            margin-top: 15px;
            text-align: center;

            .button {
                float: none;
            }
        }

        .create-review {
            textarea {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .form-horizontal {
                .form-group {
                    .reviews-stars {
                        &.hovered, &.clicked {
                            [type="radio"] {
                                & + label {
                                    &:before {
                                        content: '\f005' !important;
                                    }
                                }

                                & + .painted ~ label {
                                    &:before {
                                        content: '\f006' !important;
                                    }
                                }
                            }
                        }

                        [type="radio"] {
                            display: none;

                            label.checked ~ label {
                                &:before {
                                    content: '\f005' !important;
                                }
                            }

                            & + label {
                                display: inline-block;
                                border: none;
                                border-radius: 0;
                                vertical-align: middle;
                                transition: all 0.2s;
                                width: 25px;
                                height: 25px;
                                cursor: pointer;

                                &:before {
                                    content: '\f006 ';
                                    font-family: 'FontAwesome';
                                    font-size: 23px;
                                    color: $baseColorRed;
                                    display: block;
                                    width: auto;
                                    height: auto;
                                    border-radius: 0;
                                    background-color: transparent;
                                }
                            }

                            &:checked + span,
                            &:checked + label {
                                &:before {
                                    content: '\f005 ';
                                    font-size: 23px;
                                    color: $baseColorRed;
                                    font-family: 'FontAwesome';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {
        .products-carousel {
            ul.products-list {
                margin-bottom: 70px;
            }
        }
    }


    @include breakpoint(extra-small) {
        .product-description {
            margin-bottom: 40px;

            &.sold {
                .content-wrapper {
                    .buying-process {
                        .buy-product {
                            button {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }

        .products-carousel {
            padding-top: 0;

            h2 {
                font-size: 25px;
                margin-bottom: 30px;
            }

            ul.products-list {
                margin-bottom: 70px;

                .custom-slide-wrapper {
                    li {
                        .purchase-wrapper {
                            .button {
                                float: right;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .customers-reviews {
            .title-review {
                margin-bottom: 15px;

                .title-wrapper {
                    h3 {
                        text-align: center;
                    }
                }

                .reviews-counter-wrapper {
                    text-align: center;

                    .button {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .customers-reviews {
            .create-review {
                form {
                    .buttons {
                        margin-top: 30px;

                        & > div {
                            float: none !important;

                            .btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}