#modal-agree {
    .product-description {
        .content-wrapper {
            .buying-process {
                .buy-product {
                    & > button {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .customers-reviews {
        ul.reviews-carousel {
            li {
                figure {
                    figcaption {
                        time {
                            float: none;

                            @include breakpoint(medium) {
                                float: right;
                            }


                            @include breakpoint(mobile) {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }
}