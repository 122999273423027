[id^='oneall_social_login'] {
    iframe {
        width: 100% !important;

        // #social_login_frame {
        //     background-color: aqua !important;

        //     .footer {
        //         display: none !important;
        //     }
        // }

        // .providers_block {
        //     #provider_facebook {
        //         .button {
        //             height: auto !important;
        //             width: auto !important;
        //             background-image: none;
        //         }
        //     }
        // }
   
    }
}