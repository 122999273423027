.ui-slider {
    position: relative;
    left: 7px;
    width: calc(100% - 14px);
    margin-top: 30px;
    margin-bottom: 20px;

    .ui-slider-handle {
        position: absolute;
        z-index: 2;
        width: 14px;
        height: 14px;
        background-color: $baseColorRed;
        cursor: pointer;
        border-radius: 0;
        border: none;
        background-image: none;

        &:focus {
            outline: none;
        }
    }

    .ui-slider-range {
        position: absolute;
        z-index: 1;
        display: block;
        overflow: hidden;
        font-size: .7em;
    }
}

.ui-slider-horizontal {
    height: 3px;

    .ui-slider-handle {
        top: -6px;
        margin-left: -7px;
    }

    .ui-slider-range {
        top: 0;
        height: 100%;
    }

    .ui-slider-range-min {
        left: 0;
    }

    .ui-slider-range-max {
        right: 0;
    }
}

.ui-widget-content {
    background: $baseBorderColor;
}

.ui-widget-header {
    background: $baseColorRed;
}

.ui-corner-all {
    border-radius: 0;
}