button.like {
    display: inline-block;
    padding: 0;
    width: 20px;
    height: 20px;
    font-family: 'FontAwesome';
    font-size: 18px;
    line-height: 1;
    color: $baseColorRed;
    background-color: transparent;
    transition: all 0.2s;

    &:before {
        content: '\f08a';
    }

    &[data-chacked="true"] {
        &:before {
            content: '\f004';
        }
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    &:focus {
        background-color: transparent !important;
    }
}