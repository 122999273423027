.checkout {
    padding-bottom: 70px;

    #accordion {
        margin-top: 25px;

        .panel {
            .panel-heading {
                .panel-title {
                    a {
                        i {
                            margin-left: 5px;
                            font-size: 14px;
                            transition: all 0.25s;

                            &:before {
                                content: '\e259';
                                font-family: 'Glyphicons Halflings';
                            }
                        }

                        &[aria-expanded="true"] {
                            i {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .panel-collapse {
                .panel-body {
                    .radio {
                        padding-top: 0;

                        // margin-bottom: 10px;

                        label {
                            padding-left: 0;

                            span {
                                margin-right: 10px;
                            }
                        }
                    }

                    [id$="existing"] {
                        margin-top: 10px;
                        margin-bottom: 20px;

                        .select-wrapper {
                            &:after {
                                z-index: 0;
                            }
                        }
                    }

                    .form-group {
                        .control-label {
                            width: 100%;
                            padding-top: 0;
                            padding-bottom: 5px;
                            text-transform: uppercase;
                            text-align: left;
                        }

                        .col-sm-10 {
                            width: 100%;
                            text-align: left;
                        }
                    }

                    table {
                        thead {
                            tr {
                                td {
                                    border-bottom-width: 1px;
                                }
                            }
                        }
                    }

                    .buttons {
                        & > div {
                            label {
                                margin-right: 10px;
                                margin-bottom: 5px;
                                border: 1px solid #a83252;
                            }

                            [type="button"], [type="submit"] {
                                &:active, &:hover {
                                    background-color: #81263f;
                                    border-color: #81263f;
                                    color: #fff;
                                }

                                &#button-payment-method {
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(mobile) {
        padding-bottom: 30px;

        #accordion {
            margin-top: 25px;

            .panel {
                .panel-heading {
                    .panel-title {
                        font-size: 14px;

                        a {
                            i {
                                font-size: 10px;
                            }
                        }
                    }
                }

                .panel-collapse {
                    .panel-body {
                        .buttons {
                            & > div {
                                float: none !important;

                                [type="button"], [type="submit"] {
                                    width: 100%;

                                    &#button-payment-method {
                                        margin-left: 0;
                                        margin-top: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}