.scroll-to-top {
    position: absolute;
    top: 0;
    right: 180px;
    display: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fce9da;
    transform: translateY(-50%);

    &:hover, &:focus, &:active {
        background-color: #fce9da;
    }

    &:focus {
        &:hover {
            background-color: #fce9da;
        }
    }

    &:hover {
        i {
            transform: translate(-50%, -70%);
        }
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 28px;
        color: $baseColorRed;
        transition: all 0.3s;
    }

    &.visible {
        display: block;
    }

    @include breakpoint(large) {
        right: 50px;
    }


    @include breakpoint(medium) {
        width: 60px;
        height: 60px;

        i {
            font-size: 24px;
        }
    }


    @include breakpoint(small) {
        right: 24px;
    }


    @include breakpoint(extra-small) {
        right: 15px;
        width: 40px;
        height: 40px;

        i {
            font-size: 18px;
        }
    }
}