.quantity-bottles-wrapper {
    display: inline-block;

    .view-all {
        position: relative;
        margin-left: 20px;
        line-height: 30px;
        color: $baseColorRed;
        transition: all 0.3s;

        &:hover {
            color: darken($baseColorRed, 10deg);

            &:after {
                animation: hover 0.5s 0s linear forwards;
            }
        }

        &:before {
            content: '|';
            position: absolute;
            left: -15px;
            color: $textColorGrey;
            font-weight: 700;
        }

        &:after {
            content: '';
            position: absolute;
            top: 90%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $baseColorRed;
        }

        @keyframes hover {
            0% {
                left: 0px;
                right: 0px;
            }

            50% {
                left: 100%;
                right: 0px;
            }

            51% {
                left: 0px;
                right: 100%;
            }

            100% {
                right: 0px;
            }
        }
    }

    @include breakpoint(small) {
        .view-all {
            &:before {
                font-weight: 400;
            }
        }
    }


    @include breakpoint(extra-small) {
        display: block;
        margin-top: 30px;
    }
}