#age-checker {
    .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;

        .modal-content {
            text-align: center;

            p {
                margin-bottom: 0;
            }

            .modal-header {
                padding-top: 40px;
            }

            .modal-body {
                display: inline-block;
                width: 65%;

                .modal-title {
                    margin-top: 20px;
                    font-size: 24px;
                    font-weight: 300;
                    color: $textColorGrey;
                }

                .date-checker {
                    margin-top: 30px;
                    margin-bottom: 20px;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 1;
                    color: $baseColorRed;
                }

                button {
                    width: 110px;
                    font-size: 24px;
                    text-transform: capitalize;

                    &:nth-of-type(n+2) {
                        margin-left: 5px;
                    }

                    &.date-checker-no {
                        background-color: #565656;

                        &:hover {
                            background-color: darken(#565656, 10deg);
                        }
                    }
                }

                .warning {
                    font-size: 21px;
                    color: $baseColorRed;
                }
            }

            .modal-footer {
                display: inline-block;
                width: 80%;
                padding-bottom: 25px;
                text-align: center;
            }
        }
    }

    @include breakpoint(extra-small) {
        .modal-dialog {
            width: calc(100% - 30px);

            .modal-content {
                line-height: 1.4;

                .modal-header {
                    padding-top: 30px;

                    .logo-wrapper {
                        img {
                            max-width: 110px;
                        }
                    }
                }

                .modal-body {
                    width: 100%;

                    .modal-title {
                        font-size: 18px;
                    }

                    .date-checker {
                        margin-top: 15px;
                        font-size: 24px;
                    }

                    button {
                        font-size: 20px;
                    }

                    .warning {
                        font-size: 18px;
                    }
                }

                .modal-footer {
                    width: 100%;
                    padding-top: 0;
                }
            }
        }
    }


    @include breakpoint(mobile-landscape) {
        .modal-dialog {
            .modal-content {
                // padding-top: 15px;
                // padding-bottom: 15px;
                line-height: 1.3;

                .modal-header {
                    display: inline-block;
                    width: 30%;
                    vertical-align: top;
                    padding-top: 15px;
                }

                .modal-body {
                    width: 63%;

                    .date-checker {
                        margin-top: 10px;
                        margin-bottom: 15px;
                    }

                    button {
                        // padding-top: 10px;
                        // padding-bottom: 10px;
                        font-size: 16px;
                        text-transform: uppercase;
                    }

                    .warning {
                        font-size: 18px;
                    }
                }

                .modal-footer {
                    // padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
        }
    }
}