.modal {
    .modal-dialog {
        .modal-content {
            box-shadow: none;
            border-radius: 0;

            .close {
                position: absolute;
                top: 15px;
                right: 15px;
            }

            // .close {
            //     font-size: 0;
            //     color: $baseColorRed;
            //     text-shadow: none;
            //     opacity: 1;

            //     &:hover {
            //         transform: rotateZ(90deg);
            //     }

            //     &:before {
            //         content: '\e014';
            //         display: block;
            //         width: 20px;
            //         height: 20px;
            //         font-family: 'Glyphicons Halflings';
            //         font-size: 20px;
            //         line-height: 1;
            //         color: $baseColorRed;
            //     }

            //     &:focus, &:hover {
            //         background-color: transparent;
            //     }
            // }

            .modal-header {
                border-bottom: 0;

                h3 {
                    text-transform: capitalize;
                }
            }

            .modal-body {
                .form-group {
                    position: relative;
                    margin-bottom: 25px;

                    label {
                        padding-top: 0;
                        text-align: left;
                        text-transform: uppercase;
                        color: #000;
                    }

                    .text-danger {
                        font-weight: 300;
                    }

                    .no-valid-regexp {
                        border-color: red;
                    }

                    .error-message,
                    .error-required {
                        position: absolute;
                        top: 100%;
                        left: 15px;
                        right: auto;
                        display: inline-block;
                        padding: 0 10px;
                        background-color: red;
                        font-size: 12px;
                        line-height: 1.3;
                        color: white;
                    }
                }

                .button-wrapper {
                    margin-top: 40px;
                    margin-bottom: 40px;

                    [type="submit"] {}
                }
            }

            .modal-footer {
                border-top: 0;
                & .btn:first-child {
                    float: left;
                }
            }
        }
    }

    &#modal-agree {
        .container {
            width: 100%;
        }

        .modal-dialog {
            width: 900px;

            .modal-content {
                .modal-body {
                    .individual-product-page-layout {
                        position: relative;

                        .product-description {
                            margin-top: 0;
                        }

                        .customers-reviews {
                            .title-review {
                                margin-bottom: 0;
                            }

                            .reviews-carousel {
                                padding-left: 0;
                                padding-right: 0;
                            }

                            .slick-prev,
                            .slick-next {
                                top: calc(100% + 20px);
                                transform: translate(0, 0);
                                width: 40px;
                                height: 40px;
                                background-color: #fff6ef;
                                line-height: 30px;

                                &:before {
                                    font-size: 38px;
                                    line-height: 38px;
                                }

                                &:hover, &:active {
                                    background-color: #fff6ef;
                                }
                            }

                            .slick-prev {
                                left: auto;
                                right: 70px;
                            }

                            .slick-next {
                                right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(small) {
        &#modal-agree {
            .container {
                width: 100%;
            }

            .modal-dialog {
                width: 700px;
                margin: 30px auto;
            }
        }
    }


    @include breakpoint(extra-small) {
        &#modal-agree {
            .modal-dialog {
                width: calc(100% - 30px);
                margin: 30px auto;
            }
        }
    }

    @include breakpoint(mobile) {
        .modal-footer {
            .btn {
                width: 100%;
                margin-left: 0;

                &:first-child {
                    float: none;
                    margin-bottom: 15px;
                }
            }
        }
    }
}