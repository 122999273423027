.testimonials {
    margin-top: 55px;

    ul.owner-reviews-carousel {
        h2 {
            margin-bottom: 0;
        }

        .testimonial-body {
            position: relative;
            margin-top: 70px;
            margin-bottom: 50px;

            /*left side*/

            .author-photo {
                position: absolute;
                top: 0;
                bottom: 0px;
                background-size: cover;
            }

            /*right side*/
            .inner-content-wrapper {
                float: right;
                margin-top: 30px;
                margin-bottom: 30px;
                margin-left: -8.33333333%;
                padding: 30px 35px;
                background-color: #fff6ef;

                .rating-stars {
                    margin-bottom: 20px;
                }

                .testimonial-content {
                    color: $textColorBrown;
                    font-weight: 300;
                }

                .athor-name {
                    display: block;
                    font-size: 20px;
                    font-weight: 700;
                    color: #000000;
                    text-transform: capitalize;
                }

                .author-position {
                    display: block;
                    font-weight: 300;
                    font-size: 16px;
                    text-transform: lowercase;
                    color: $baseColorRed;
                }
            }
        }

        @media (min-width: 1200px) {
            .slick-arrow {
                top: calc(50% + 7px);
            }

            .slick-prev {
                left: -70px;
            }

            .slick-next {
                right: -70px;
            }
        }

        @include breakpoint(small) {
            .slick-arrow {
                top: 100%;
            }
        }


        @include breakpoint(extra-small) {
            .slick-arrow {
                top: calc(100% + 15px);
            }

            .testimonial-body {
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 0;
                margin-top: 35px;

                /*left side*/

                .author-photo {
                    position: relative;
                    height: 400px;
                    margin-left: -15px;
                    width: calc(100% + 30px);
                }

                /*right side*/
                .inner-content-wrapper {
                    float: left;
                    margin-top: -60px;
                    margin-left: 0;
                    margin-bottom: 0;

                    .rating-stars {}

                    .testimonial-content {}

                    .athor-name {}

                    .author-position {}
                }
            }
        }


        @include breakpoint(mobile) {
            .testimonial-body {
                /*left side*/

                .author-photo {
                    height: 255px;
                }

                /*right side*/
                .inner-content-wrapper {
                    .rating-stars {}

                    .testimonial-content {}

                    .athor-name {}

                    .author-position {}
                }
            }
        }
    }

    @include breakpoint(medium) {
        padding-bottom: 80px;
    }
}