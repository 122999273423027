// Proxima Nova

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Thin.otf");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Thin Italic.otf");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Light.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Light Italic.otf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Regular.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Regular Italic.otf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Semibold.otf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Semibold Italic.otf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Bold.otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Bold Italic.otf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Extrabold.otf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Extrabold Italic.otf");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Black.otf");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/proxima-nova/Proxima Nova Black Italic.otf");
    font-weight: 900;
    font-style: italic;
}

// Campton fonts

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-ExtraLight.otf");
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-ExtraLightItalic.otf");
//     font-weight: 100;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Thin.otf");
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-ThinItalic.otf");
//     font-weight: 200;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Light.otf");
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-LightItalic.otf");
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Book.otf");
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-BookItalic.otf");
//     font-weight: 400;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Medium.otf");
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-MediumItalic.otf");
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-SemiBold.otf");
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-SemiBoldItalic.otf");
//     font-weight: 600;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Bold.otf");
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-BoldItalic.otf");
//     font-weight: 700;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-ExtraBold.otf");
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-ExtraBoldItalic.otf");
//     font-weight: 800;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-Black.ttf");
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Campton';
//     src: url("../fonts/campton/Campton-BlackItalic.ttf");
//     font-weight: 900;
//     font-style: italic;
// }

// Century751 fonts

@font-face {
    font-family: 'Century751';
    src: url("../fonts/century751/Century751 BT Roman.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Century751';
    src: url("../fonts/century751/Century751 BT Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Century751';
    src: url("../fonts/century751/Century751 BT Semi Bold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Century751';
    src: url("../fonts/century751/Century751 BT Semi Bold Italic.ttf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Century751 No2';
    src: url("../fonts/century751/Century751 No2 BT.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Century751 No2';
    src: url("../fonts/century751/Century751 No2 BT Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Century751 No2';
    src: url("../fonts/century751/Century751 No2 BT Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Century751 No2';
    src: url("../fonts/century751/Century751 No2 BT Bold Italic.ttf");
    font-weight: 700;
    font-style: italic;
}

// fontello icon fonts
@font-face {
    font-family: 'fontello';
    src: url("../fonts/fontello/font/fontello.eot?50080803");
    src: url("../fonts/fontello/font/fontello.eot?50080803#iefix") format("embedded-opentype"), url("../fonts/fontello/font/fontello.woff2?50080803") format("woff2"), url("../fonts/fontello/font/fontello.woff?50080803") format("woff"), url("../fonts/fontello/font/fontello.ttf?50080803") format("truetype"), url("../fonts/fontello/font/fontello.svg?50080803#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}