table {
    &.table-bordered {
        border: 1px solid $baseColorRed;
    }

    &.table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    thead, tbody, tfoot {
        tr {
            td {
                border: 1px solid $baseColorRed !important;

                &.text-left, &.text-center, &.text-right {
                    border: 1px solid $baseColorRed;
                }
            }
        }
    }

    thead {
        background-color: rgba(168, 50, 82, 0.08);
    }
}