.catalog {
    padding-top: 20px;
    padding-bottom: 50px;

    .aside-filters-wrapper {
        .show-filters {
            display: none;
        }

        .panel {
            margin-bottom: 0;
            border: none;
            border-radius: 0;
            box-shadow: none;
            overflow: hidden;
            transition: all 0.25s;

            .panel-heading {
                display: none;
            }

            .list-group {
                li {
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:nth-of-type(n+2) {
                        margin-top: 25px;
                    }

                    [data-toggle="collapse"] {
                        display: block;
                        padding-top: 0;
                        padding-right: 20px;
                        padding-bottom: 0;
                        padding-left: 0;
                        font-weight: 300;
                        color: #000;
                        text-transform: uppercase;
                        background-color: transparent;

                        &[aria-expanded="true"] {
                            color: $baseColorRed;

                            i {
                                transform: translateY(-50%) rotateZ(180deg);
                            }
                        }

                        i {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 12px;
                            line-height: 1;
                            transition: all 0.25s;

                            &:before {
                                line-height: 14px;
                            }
                        }
                    }

                    .panel-collapse {
                        [id^="filter-group"] {
                            padding-top: 15px;

                            .checkbox {
                                margin-top: 0;
                                margin-bottom: 0;
                                text-align: left;

                                label {
                                    padding-left: 0;
                                    font-weight: 300;
                                }

                                &:nth-child(n+2) {
                                    margin-top: 5px;
                                }

                                &:last-of-type {
                                    margin-bottom: 15px;
                                }
                            }
                        }

                        &#filter-price {
                            width: 90%;

                            [type="number"] {
                                width: 45%;
                                height: 38px;
                                line-height: 38px;
                                padding-right: 0;

                                // padding: 10px 15px;
                                border-color: $baseColorRed;
                                -moz-appearance: textfield;

                                &:hover,
                                &:focus {
                                    -moz-appearance: number-input;
                                }

                                &:first-of-type {
                                    float: left;
                                }

                                &:nth-of-type(n+2) {
                                    float: right;
                                }

                                // &::-webkit-inner-spin-button,
                                // &::-webkit-outer-spin-button {
                                //     -webkit-appearance: None;
                                //     margin: 0;
                                // }

                                &::-webkit-inner-spin-button {
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }

                            .ui-slider {
                                margin-top: 70px;
                            }
                        }
                    }
                }
            }

            .panel-footer {
                margin-top: 30px;
                margin-bottom: 20px;
                padding: 0;
                background-color: transparent;
                border: none;
                text-align: left;

                #button-filter {
                    width: 100%;
                    color: #625951;
                    text-transform: uppercase;
                    background-color: $baseBgColorPale;

                    &:hover {
                        background-color: darken($baseBgColorPale, 5deg);
                    }
                }
            }
        }

        .banner-wrapper {
            margin-top: 10px;

            img {
                width: 100%;
            }
        }
    }

    .content-wrapper {
        .filters-wrapper {
            label {
                font-size: 16px;
                font-weight: 700;
                margin-right: 15px;
                margin-bottom: 0;
            }

            ul.filters-list {
                display: inline-block;
                font-size: 0;

                li {
                    display: inline-block;
                    margin-bottom: 10px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    .button {
                        padding: 0 10px;
                        height: 30px;
                        line-height: 30px;
                        min-width: 115px;
                        font-size: 13px;
                        font-weight: 300;
                        color: $textColorGrey;
                        text-transform: uppercase;
                        background-color: $baseBgColorPale;

                        &:hover {
                            background-color: darken($baseBgColorPale, 5deg);
                        }

                        &.checked {
                            color: #fff;
                            background-color: $baseColorRed;
                        }
                    }

                    &:last-child {
                        .button {
                            min-width: auto;
                            padding-right: 0;
                            font-size: 16px;
                            color: $baseColorRed;
                            text-transform: lowercase;
                            background-color: transparent;

                            &:before {
                                content: '\e014';
                                display: inline-block;
                                margin-right: 5px;
                                font-family: 'Glyphicons Halflings';
                                font-size: 18px;
                                line-height: 1;
                                vertical-align: middle;
                                transition: all 0.3s;
                            }

                            &:hover {
                                &:before {
                                    transform: rotateZ(90deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .view-sort-wrapper {
            margin-top: 30px;
            font-size: 14px;
            line-height: 30px;
            font-weight: 300;
            color: $textColorGrey;

            .showing-wrapper {
                display: inline-block;
                margin-left: 15px;
            }

            .select-wrapper {
                position: relative;
                float: right;
                display: flex;

                label {
                    margin-right: 10px;
                    margin-bottom: 0;
                    font-weight: 300;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                select {
                    width: 200px;
                    height: 30px;
                    line-height: 30px;
                }

                &:after {
                    right: 10px;
                }
            }
        }

        .product-cards-wrapper {
            margin-top: 30px;
            margin-left: -15px;
            margin-right: -15px;

            .product-card {
                width: calc(33.33% - 30px);
                margin-bottom: 30px;

                .purchase-wrapper {
                    .button {
                        min-width: 140px;
                    }
                }

                &.recommended-product {
                    width: calc(66.66% - 30px);
                }
            }

            h2 {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 30px;
                margin-bottom: 50px;
            }
        }
    }

    @include breakpoint(medium) {
        .aside-filters-wrapper {
            .panel {
                .list-group {
                    li {
                        .panel-collapse {
                            &#filter-price {
                                width: 100%;

                                [type="number"] {
                                    width: 46%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .content-wrapper {
            .filters-wrapper {
                label {
                    display: block;
                    margin-right: 0;
                }

                ul.filters-list {
                    display: block;
                    margin-top: 20px;
                }
            }

            .view-sort-wrapper {
                .showing-wrapper {
                    margin-left: 10px;
                }
            }

            .product-cards-wrapper {
                .product-card {
                    width: calc(50% - 30px);

                    &.recommended-product {
                        width: 100%;
                    }
                }
            }
        }
    }


    @include breakpoint(small) {
        .aside-filters-wrapper {
            .show-filters {
                display: block;
                width: 100%;
                background-color: #000;
                color: #fff;
                text-transform: uppercase;

                i {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    transition: all 0.3s;
                }

                &[aria-expanded="true"] {
                    i {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            .panel {
                padding-left: 25px;
                padding-right: 25px;
                border-right: 1px solid $baseBorderColor;
                border-left: 1px solid $baseBorderColor;
                border-bottom: 1px solid $baseBorderColor;

                .list-group {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        display: inline-block;
                        width: 50%;

                        &:nth-of-type(n) {
                            margin-top: 15px;
                        }

                        .panel-collapse {
                            &#filter-price {
                                width: 90%;

                                [type="number"] {
                                    width: 45%;
                                }
                            }
                        }

                        [data-toggle="collapse"] {
                            font-size: 13px;
                        }
                    }
                }

                .panel-footer {
                    #button-filter {
                        width: auto;
                    }
                }
            }

            .banner-wrapper {
                display: none;
            }
        }

        .content-wrapper {
            margin-top: 20px;

            .filters-wrapper {
                label {
                    font-size: 14px;
                }
            }

            .product-cards-wrapper {
                .product-card {
                    .purchase-wrapper {
                        .button {
                            min-width: 225px;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .aside-filters-wrapper {
            .show-filters {
                font-size: 13px;
            }

            .panel {
                padding-left: 15px;
                padding-right: 15px;

                .list-group {
                    li {
                        width: 100%;

                        .panel-collapse {
                            &#filter-price {
                                width: 100%;
                                max-width: 260px;
                            }
                        }
                    }
                }

                .panel-footer {
                    #button-filter {
                        width: 100%;
                        font-size: 13px;
                    }
                }
            }

            .banner-wrapper {
                display: none;
            }
        }

        .content-wrapper {
            .filters-wrapper {
                ul.filters-list {
                    margin-top: 10px;
                }
            }

            .view-sort-wrapper {
                .showing-wrapper {
                    display: block;
                    margin-left: 0;
                }

                .select-wrapper {
                    width: 300px;
                    float: none;

                    select {
                        width: 100%;
                    }
                }
            }

            .product-cards-wrapper {
                .product-card {
                    margin-bottom: 15px;
                }

                h2 {
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }

            .view-pagination-wrapper {
                text-align: center;

                .quantity-bottles-wrapper {
                    margin-top: 10px;
                    margin-bottom: 25px;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .aside-filters-wrapper {
            .panel {
                .list-group {
                    li {
                        width: 100%;

                        .panel-collapse {
                            &#filter-price {
                                max-width: none;
                            }
                        }

                        &:nth-child(n+2) {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .content-wrapper {
            .view-sort-wrapper {
                .select-wrapper {
                    width: 100%;
                }
            }
        }
    }


    @include breakpoint(mobile-landscape) {
        .aside-filters-wrapper {
            .panel {
                .list-group {
                    li {
                        width: 50%;

                        .panel-collapse {
                            &#filter-price {
                                width: 90%;
                            }
                        }
                    }
                }

                .panel-footer {
                    #button-filter {
                        width: auto;
                    }
                }
            }
        }
    }
}
