.customers-reviews {
    margin-top: 30px;
    padding-top: 60px;
    padding-bottom: 110px;

    h3 {
        text-align: left;
    }

    .reviews-quantity {
        float: right;
    }

    ul.reviews-carousel {
        margin-top: 35px;

        li {
            figure {
                background-color: #ffffff;
                position: relative;

                .author-photo {}

                figcaption {
                    // height: 200px;
                    // overflow: hidden;

                    // &:before {
                    //     content: "";
                    //     position: absolute;
                    //     top: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     left: 0;
                    //     display: block;
                    //     box-shadow: inset -4px -53px 65px -15px rgba(255, 255, 255, 1);
                    //     transition: all 0.4s;
                    // }

                    .review-content-wrapper {
                        position: relative;
                        height: 115px;
                        overflow: hidden;
                        transition: all 0.4s;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            display: block;
                            background: linear-gradient(to bottom, transparent 50%, #fff 100%);
                            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, #fff 100%);
                            transition: all 0.4s;
                        }

                        &.full-list:before {
                            top: 100%;
                        }

                        .review-content {}
                    }

                    .name {
                        display: block;
                        margin-top: 15px;
                        line-height: 1;
                        text-align: right;
                        text-transform: capitalize;
                    }
                }

                .view-more {
                    position: absolute;
                    left: 15px;
                    bottom: 35px;
                    padding: 0;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: $baseColorRed;
                    background-color: transparent;
                }
            }
        }
    }

    @include breakpoint(extra-small) {
        margin-top: 50px;

        h3 {
            float: none;
            text-align: left;
            margin-bottom: 30px;
        }

        .reviews-quantity {
            float: none;
            text-align: left;
        }
    }


    @include breakpoint(mobile) {
        .reviews-quantity {
            text-align: center;
            display: block;
            width: 100%;
        }
    }
}