.form-control {
    border-radius: 0;
    border-color: #dfdfdf;
    padding: 25px 15px;
    box-shadow: none;
    z-index: 0!important;

    &:focus {
        box-shadow: none;
        border-color: #dfdfdf;
    }
}