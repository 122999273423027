.hot-marker {
    height: 50px;
    width: 50px;
    background-color: $baseColorRed;
    color: #ffffff;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    border-radius: 50%;
    line-height: 3.5;
    font-size: 14px;
    font-weight: bold;
}