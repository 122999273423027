.social-icons {
    margin-top: 18px;
    font-family: 'FontAwesome';
    line-height: 3.6;

    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 12px;
        }

        a {
            display: block;
            height: 30px;
            width: 30px;
            background-color: #a83252;
            border-radius: 50%;
            text-align: center;
            transition: all 0.5s;

            i {
                color: #000000;
                font-size: 22px;
            }

            &:hover {
                border-radius: 15px 0;
            }
        }
    }
}