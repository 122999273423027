.welcome-section {
    padding-top: 210px;
    padding-bottom: 120px;
    text-align: center;
    color: #ffffff;
    background-image: url("../image/welcome-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;

    p {
        font-weight: 100;
        font-size: 18px;
    }

    .button {
        margin-top: 25px;
        font-weight: 300;
        color: #fce9da;
        border: 2px solid #fce9da;
        background-color: transparent;
        text-transform: uppercase;
        transition: all 0.2s;

        // &:hover, &:focus {
        //     box-shadow: 0px 0px 15px 0px rgba(252, 233, 218, 1);
        //     background-color: transparent;
        // }

        &:focus {
            &:hover {
                background-color: transparent;
            }
        }
    }

    @include breakpoint(medium) {
        padding-top: 190px;
        padding-bottom: 100px;
    }


    @include breakpoint(small) {
        padding-top: 150px;
        padding-bottom: 80px;
    }


    @include breakpoint(extra-small) {
        padding-top: 105px;
        padding-bottom: 55px;

        p {
            font-size: 14px;
        }
    }


    @include breakpoint(mobile) {
        padding-top: 55px;
        padding-bottom: 65px;
    }
}