.subscribe {
    padding-bottom: 100px;

    .row {
        padding-left: 15px;
        padding-right: 15px;
    }

    .content-wrapper {
        padding: 60px 25px;
        border: 1px solid #dfdfdf;

        .offer {
            padding-left: 0;
            padding-right: 0;
            text-transform: uppercase;
            font-size: 19px;
            line-height: 1.4;
            font-weight: 300;
            color: #000;

            span {
                font-weight: 700;
            }
        }

        .form-wrapper {
            padding-right: 0;
        }

        #mc_embed_signup {
            #mc-embedded-subscribe-form {
                .mc-field-group {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 0;

                    input[type='email'] {
                        padding: 0 20px;
                        height: 52px;
                        line-height: 52px;
                    }

                    .no-valid-regexp {}

                    .error-message,
                    .error-required {
                        position: absolute;
                        top: 100%;
                        left: 15px;
                        right: auto;
                        display: inline-block;
                        padding: 0 10px;
                        background-color: red;
                        font-size: 12px;
                        line-height: 1.3;
                        color: white;
                    }
                }

                [type="submit"] {
                    padding-top: 18px;
                    padding-bottom: 18px;
                    text-transform: uppercase;
                    font-weight: 700;

                    // &:hover {
                    //     background-color: $baseColorRed;
                    // }
               
                }
            }
        }
    }

    @include breakpoint(medium) {
        padding-bottom: 40px;

        .content-wrapper {
            .offer {
                font-size: 15px;
                line-height: 1.6;
            }
        }
    }


    @include breakpoint(small) {
        .content-wrapper {
            padding: 40px 20px;

            .offer {
                font-size: 20px;
                line-height: 1.4;
            }

            #mc_embed_signup {
                padding: 0;

                #mc-embedded-subscribe-form {
                    margin-top: 20px;

                    .mc-field-group {
                        padding-left: 0;

                        input[type='email'] {
                            padding: 0 20px;
                        }

                        .error-message,
                        .error-required {
                            left: 0;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .content-wrapper {
            margin-right: 0;
            margin-left: 0;
            padding: 30px 15px;

            .offer {
                font-size: 14px;
            }

            #mc_embed_signup {
                padding: 0;

                #mc-embedded-subscribe-form {
                    margin-top: 20px;

                    [type="submit"] {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .content-wrapper {
            #mc_embed_signup {
                #mc-embedded-subscribe-form {
                    .mc-field-group {
                        width: 100%;
                        padding-right: 0;

                        input[type='email'] {
                            padding: 0 15px;
                        }
                    }

                    [type="submit"] {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}