input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'] {
    display: inline-block;
    width: 100%;
    height: 54px;
    line-height: 54px;
    padding: 0 15px;
    border: 1px solid $baseBorderColor;
    font-family: $baseSansSerifFontFamily;
    font-size: 1em;
    font-weight: 300;
    color: #000;
    text-align: left;

    // vertical-align: center;
    background-color: #fff;
    box-shadow: none !important;
    appearance: none;
    transition: all 0.3s;

    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $baseBorderColor;

        &::-webkit-input-placeholder {
            @include focus-placeholder-style;
        }

        &::-moz-placeholder {
            @include focus-placeholder-style;
        }

        &:-ms-input-placeholder {
            @include focus-placeholder-style;
        }
    }

    &[disabled],
    &[readonly] {
        cursor: default;
        color: #eee;
    }

    &::-webkit-input-placeholder {
        @include placeholder-style;
    }

    &::-moz-placeholder {
        @include placeholder-style;
    }

    &:-ms-input-placeholder {
        @include placeholder-style;
    }
}