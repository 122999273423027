[type="checkbox"] {
    display: none;

    &:checked + span,
    &:checked + label {
        border-color: $baseColorRed;

        &:before {
            font-size: 16px;
        }
    }

    & + span,
    & + label {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0;
        border: 1px solid $baseBorderColor;
        vertical-align: middle;
        transition: all 0.2s;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '\f00c';
            font-family: 'FontAwesome';
            font-size: 0px;
            line-height: 1;
            color: $baseColorRed;
            transition: all 0.2s;
        }

        &:hover {
            cursor: pointer;
        }
    }
}