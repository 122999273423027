.search {
    padding-bottom: 70px;

    h1 {
        margin-bottom: 50px;
    }

    label[for="input-search"] {
        text-transform: uppercase;
    }

    .checkbox-inline {
        padding-left: 0;
    }

    .product-field-wrapper {
        margin-bottom: 30px;
    }

    .subcategory-field-wrapper {
        label {
            // cursor: no-drop;
            display: block;

            &:nth-of-type(n+2) {
                margin-top: 5px;
                margin-left: 0;
            }

            span {
                margin-right: 5px;
            }
        }
    }

    .category-field-wrapper {
        &.select-wrapper {
            &:after {
                right: 30px;
            }
        }
    }

    .button-search-wrapper {
        margin-top: 30px;
        text-align: center;

        #button-search {
            &:active:hover, &:active:focus {
                color: #fff;
                background-color: $baseColorRed;
            }
        }
    }

    h2 {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    .view-sort-wrapper {
        .showing-wrapper {
            padding-top: 8px;
            padding-bottom: 8px;
            line-height: 1;
        }

        .select-wrapper {
            margin-bottom: 0;

            label {
                border-radius: 0;
                background-color: transparent;
                margin-right: 10px;
                padding-left: 0;
                font-size: 14px;
                line-height: 1;
                font-weight: 300;
                text-transform: uppercase;
                white-space: nowrap;
            }

            select {
                height: 30px;
                line-height: 30px;
            }
        }
    }

    .product-cards-wrapper {
        margin-top: 15px;

        .product-card {
            &:nth-of-type(5n) {
                width: calc(33.33% - 30px);

                .product-description-wrapper {
                    h6 {
                        min-height: calc(1em * 1.6 * 2);
                        font-size: 11px;
                        line-height: 1.6;
                    }
                }

                .purchase-wrapper {
                    right: 15px;
                    left: auto;
                    width: auto;
                    padding-left: 0;

                    .button {
                        max-width: 140px;
                    }
                }
            }

            .purchase-wrapper {
                .button {
                    min-width: 140px;
                }
            }
        }
    }

    @include breakpoint(small) {
        h2 {
            margin-bottom: 15px;
        }

        .view-sort-wrapper {
            .showing-wrapper {
                margin-bottom: 15px;
                text-align: center;
            }

            .select-wrapper {
                margin-bottom: 15px;
            }
        }

        .product-cards-wrapper {
            .product-card, .product-card:nth-of-type(5n) {
                width: calc(50% - 30px);
            }
        }
    }


    @include breakpoint(extra-small) {
        h1 {
            margin-bottom: 20px;
        }

        label[for="input-search"] {
            text-align: center;
        }

        .product-field-wrapper {
            margin-bottom: 15px;
        }

        .subcategory-field-wrapper {
            margin-top: 15px;
        }
    }


    @include breakpoint(mobile) {
        .button-search-wrapper {
            #button-search {
                width: 100%;
            }
        }
    }
}