.wishlist-wrapper {
    padding-bottom: 80px;

    .title-wrapper {
        text-align: center;

        p {
            font-weight: 300;
        }
    }

    .added-info-wrapper {
        position: relative;
        margin-top: 20px;
        text-align: center;

        .social-sharing-wrapper {
            .social-sharing-buttons {
                height: 0;
                opacity: 0;
                transition: all 0.3s;

                &.visible {
                    height: auto;
                    opacity: 1;
                }
            }
        }

        .added-info {
            position: absolute;
            left: 15px;

            a {
                padding-right: 25px;
                font-size: 16px;
                font-weight: 700;

                & > i {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 12px;
                    color: $textColorGrey;
                }

                &[aria-expanded="true"] {
                    & > i {
                        &:before {
                            content: "\e260";
                        }
                    }
                }
            }

            .dropdown-menu {
                min-width: 120px;
            }
        }

        .items-counter-wrapper {
            display: inline-block;
            color: $baseColorRed;
        }

        .share {
            position: absolute;
            right: 15px;
            padding-right: 30px;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 700;
            z-index: 1;

            &:before {
                content: '\f1e0';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                display: block;
                width: 20px;
                height: 20px;
                font-family: 'FontAwesome';
                font-size: 20px;
                line-height: 1;
                color: $baseBgColorPale;
            }
        }
    }

    .product-cards-wrapper {
        // margin-top: 30px;

        .product-card {
            display: none;
            width: calc(25% - 30px);
            margin-top: 50px;
            margin-bottom: 15px;

            .purchase-wrapper {
                .button {
                    min-width: 140px;
                }
            }

            &:nth-of-type(-n+8) {
                display: block;
            }

            &:nth-of-type(n+9) {
                opacity: 0;
                transition: all 1s;
            }

            &.show {
                opacity: 1;
            }
        }

        .see-all-button-wrapper {
            display: none;
            width: 100%;
            margin-top: 80px;
            text-align: center;

            button {
                padding-left: 60px;
                padding-right: 60px;
                text-transform: uppercase;
            }
        }
    }

    @include breakpoint(medium) {
        .product-cards-wrapper {
            .product-card {
                width: calc(33.33% - 30px);
            }

            .see-all-button-wrapper {
                margin-top: 50px;
            }
        }
    }


    @include breakpoint(small) {
        .title-wrapper {
            margin-top: 30px;
        }

        .product-cards-wrapper {
            margin-top: 40px;

            .product-card {
                width: calc(50% - 30px);
                margin-top: 20px;

                .purchase-wrapper {
                    width: 50%;

                    .button {
                        min-width: 100%;
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .title-wrapper {
            margin-top: 30px;
        }

        .added-info-wrapper {
            text-align: right;

            .added-info {
                position: absolute;
                left: 15px;

                a {
                    padding-right: 20px;
                    font-size: 14px;

                    & > i {
                        color: #000;
                    }
                }

                .dropdown-menu {
                    right: auto;
                    left: 0;
                }
            }

            .items-counter-wrapper {
                display: inline-block;
                color: $baseColorRed;
            }

            .share {
                display: none;
            }
        }

        .product-cards-wrapper {
            .product-card {
                margin-top: 0;
            }
        }
    }


    @include breakpoint(mobile-landscape) {
        .product-cards-wrapper {
            .product-card {
                .purchase-wrapper {
                    width: calc(50% - 30px);
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .product-cards-wrapper {
            .see-all-button-wrapper {
                padding-left: 15px;
                padding-right: 15px;

                button {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}