.slick-slider {
    .slick-prev,
    .slick-next {
        width: auto;
        height: auto;
        z-index: 1;

        &:before {
            font-family: 'FontAwesome';
            font-size: 80px;
            line-height: 1;
            color: $baseColorRed;
            opacity: 1;
            transition: all 0.25s;
        }

        &:hover, &:active {
            background-color: transparent;

            &:before {
                color: darken($baseColorRed, 10deg);
            }
        }
    }

    .slick-prev {
        left: -30px;

        &:before {
            content: "\f104";
        }
    }

    .slick-next {
        right: -30px;

        &:before {
            content: "\f105";
        }
    }

    &:focus {
        outline: none;
    }

    .slick-track {
        &:focus {
            outline: none;
        }

        .slick-slide {
            &:focus {
                outline: none;
            }
        }
    }

    @media (max-width: 1250px) {
        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }

    @include breakpoint(medium) {
        .slick-prev,
        .slick-next {
            top: calc(100% + 20px);
            transform: translate(0, 0);
            width: 40px;
            height: 40px;
            background-color: #fff6ef;
            line-height: 30px;

            &:before {
                font-size: 38px;
                line-height: 38px;
            }

            &:hover, &:active {
                background-color: #fff6ef;
            }
        }

        .slick-prev {
            left: auto;
            right: 70px;
        }

        .slick-next {
            right: 15px;
        }
    }


    @include breakpoint(extra-small) {
        .slick-prev,
        .slick-next {
            top: calc(100% + 15px);
        }

        .slick-prev {
            left: 15px;
            right: auto;
        }

        .slick-next {
            right: 15px;
        }
    }
}