.about-us {
    flex: 1;
    padding-bottom: 125px;

    h1 {
        margin-bottom: 60px;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        color: $textColorGrey;
    }

    .blockquote {
        position: relative;
        padding-left: 38%;
        margin-top: 60px;
        font-style: italic;
        color: #ccb09a;

        &:before, &:after {
            position: absolute;
            display: inline-block;
            font-family: 'FontAwesome';
            font-size: 30px;
            line-height: 1;
            font-style: normal;
            color: $baseBorderColor;
        }

        &:before {
            content: '\f10d';
            top: 0;
            left: calc(38% - 50px);
        }

        &:after {
            content: '\f10e';
            bottom: 0;
            right: -45px;
        }
    }

    .author {
        margin-top: 35px;
        text-align: right;
        font-family: $baseSerifFontFamily;
        font-size: 24px;
        color: #000;
    }

    img {
        margin-top: 70px;
    }

    @include breakpoint(leptop) {
        .blockquote {
            padding-right: 40px;
            text-align: justify;

            &:after {
                right: 0;
            }
        }

        .author {
            margin-top: 25px;
            margin-right: 40px;
        }
    }


    @include breakpoint(small) {
        padding-top: 20px;
        padding-bottom: 70px;

        h1 {
            margin-bottom: 30px;
        }

        p {
            line-height: 1.4;
        }

        .blockquote {
            margin-top: 40px;
            padding-right: 30px;

            &:before, &:after {
                font-size: 24px;
            }

            &:before {
                left: calc(38% - 35px);
            }
        }

        .author {
            margin-right: 30px;
            font-size: 20px;
        }

        img {
            margin-top: 40px;
        }
    }


    @include breakpoint(extra-small) {
        .blockquote {
            margin-top: 25px;
            padding-left: 30px;
            text-align: justify;

            &:before, &:after {
                font-size: 20px;
            }

            &:before {
                left: 0;
            }
        }

        .author {
            margin-top: 15px;
            font-size: 16px;
        }

        img {
            margin-top: 25px;
        }
    }
}