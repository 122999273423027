.not-found-wrapper {
    min-height: 750px;
    text-align: center;
    background-image: url("../image/404-bg.png");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;

    h1 {
        margin-top: 100px;
        margin-bottom: 0;
        font-size: 120px;
        line-height: 1;
        font-weight: 700;
        color: $baseColorRed;
    }

    p {
        margin-top: 60px;
        text-transform: uppercase;
        font-size: 18px;
        color: #939393;
    }

    @include breakpoint(medium) {
        min-height: 600px;

        h1 {
            margin-top: 80px;
            font-size: 100px;
        }

        p {
            margin-top: 50px;
            font-size: 16px;
        }
    }


    @include breakpoint(small) {
        min-height: calc(100vh - 432px);

        h1 {
            font-size: 80px;
        }

        p {
            margin-top: 40px;
        }
    }


    @include breakpoint(extra-small) {
        min-height: calc(100vh - 216px);
        padding-bottom: 50px;

        h1 {
            margin-top: 50px;
            font-size: 60px;
        }

        p {
            font-size: 14px;
        }
    }
}