time {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 14px;
    font-size: 12px;
    color: #ffffff;
    background-color: $baseColorRed;

    span {
        &:not(:last-child) {
            &:after {
                content: "/";
                display: inline-block;
                height: 10px;
                width: 2px;
                margin-right: 1px;
                margin-left: 3px;
            }
        }
    }
}