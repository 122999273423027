.subscription-confirmed {
    position: relative;
    min-height: calc(100vh - 492px);
    background-image: url("../image/subscription-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, 0.9);
    }

    h1 {
        margin-top: 100px;
        margin-bottom: 0;
        color: #a83252;
    }

    p {
        margin-top: 30px;
        margin-bottom: 100px;
        font-size: 18px;
    }

    @include breakpoint(medium) {
        min-height: calc(100vh - 486px);
    }


    @include breakpoint(small) {
        min-height: calc(100vh - 427px);

        p {
            margin-top: 20px;
        }
    }


    @include breakpoint(extra-small) {
        height: auto;
        min-height: calc(100vh - 613px);

        h1 {
            margin-top: 60px;
        }

        p {
            margin-top: 10px;
            margin-bottom: 60px;
        }
    }


    @include breakpoint(mobile) {
        h1 {
            margin-top: 30px;
        }

        p {
            margin-bottom: 30px;
            line-height: 1.5;
        }
    }
}