.rating-stars {
    .star {
        display: inline-block;
        font-size: 15px;
        color: $baseColorRed;

        &:not(:last-child) {
            margin-right: 5px;
        }

        &.empty {}
    }
}