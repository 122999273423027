.panel, .panel-default, .ulogin_panel {
    text-align: center;
    border-radius: 0;
    border: none;
    box-shadow: none;

    .panel-heading {
        margin-bottom: 30px;
        font-family: $baseSerifFontFamily;
        font-size: 25px;
        letter-spacing: 1.3px;
        background-color: transparent;
        text-transform: uppercase;
        border-bottom: none;
    }

    .ulogin-buttons-container {
        height: auto !important;
        width: auto !important;

        .ulogin-button-facebook, .ulogin-button-googleplus, .ulogin-button-twitter {
            width: auto !important;
            height: auto !important;
            padding: 20px 40px !important;
            background: none !important;
            color: #ffffff !important;
            text-transform: uppercase !important;
            font-weight: bold !important;

            &:before {
                font-family: FontAwesome;
                font-size: 25px;
                vertical-align: middle;
                margin-right: 25px;
            }

            &:after {}
        }

        .ulogin-button-facebook {
            background-color: #4270a5 !important;

            &:before {
                content: "\f09a";
            }

            &:after {
                content: 'facebook';
            }
        }

        .ulogin-button-googleplus {
            background-color: #df6053 !important;

            &:before {
                content: "\f0d5";
            }

            &:after {
                content: 'google';
            }
        }

        .ulogin-button-twitter {
            background-color: #1ac9f4 !important;

            &:before {
                content: "\f099";
            }

            &:after {
                content: 'twitter';
            }
        }
    }

    @include breakpoint(small) {
        .ulogin-buttons-container {
            .ulogin-button-facebook, .ulogin-button-googleplus, .ulogin-button-twitter {
                padding: 20px 20px !important;

                &:before {
                    font-size: 20px;
                    margin-right: 20px;
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        .panel-heading {
            margin-bottom: 15px;
            font-size: 22px;
        }

        .ulogin-buttons-container {
            .ulogin-button-facebook, .ulogin-button-googleplus, .ulogin-button-twitter {
                padding: 20px 15px !important;
                margin-right: 10px;

                &:before {
                    margin-right: 15px;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .ulogin-buttons-container {
            .ulogin-button-facebook, .ulogin-button-googleplus, .ulogin-button-twitter {
                padding: 20px 70px !important;
                float: none !important;
                display: block !important;
                margin-right: 0 !important;
            }
        }
    }
}