.success {
    padding-bottom: 125px;

    #content {
        text-align: center;

        h1 {
            margin-bottom: 60px;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            color: $textColorGrey;
        }
    }

    .button-wrapper {
        margin-top: 30px;
    }

    @include breakpoint(leptop) {
        min-height: calc(100vh - 535px);
    }


    @include breakpoint(small) {
        min-height: calc(100vh - 455px);
        padding-top: 20px;
        padding-bottom: 70px;

        #content {
            h1 {
                margin-bottom: 30px;
            }
        }
    }
}