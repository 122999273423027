.alert {
    position: relative;
    padding-right: 50px;
    border-radius: 0;
    line-height: 1.4;

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &.alert-success {
        color: green;
        border-color: green;

        .close {
            &:before {
                color: green;
            }
        }
    }

    &.alert-danger {
        color: #a83252;
        border-color: #a83252;
    }

    &.alert-info {
        color: $textColorGrey;
        border-color: $textColorGrey;

        .close {
            &:before {
                color: $textColorGrey;
            }
        }
    }

    &.alert-success,
    &.alert-danger,
    &.alert-info {
        background-color: transparent;
    }
}

.close {
    font-size: 0;
    color: $baseColorRed;
    text-shadow: none;
    opacity: 1;

    &:hover {
        transform: rotateZ(90deg);
    }

    &:before {
        content: '\e014';
        display: block;
        width: 20px;
        height: 20px;
        font-family: 'Glyphicons Halflings';
        font-size: 20px;
        line-height: 1;
        color: $baseColorRed;
    }

    &:focus, &:hover, &:active {
        background-color: transparent;
        opacity: 1;
    }

    &:focus:hover {
        background-color: transparent;
    }
}

.panel-group {
    .panel {
        border-radius: 0;

        .panel-heading {
            margin-bottom: 0px;
        }
    }

    .panel-heading {
        & + .panel-collapse {
            .list-group,
            .panel-body {
                border-top: 0;
            }
        }
    }
}

.img-thumbnail {
    padding: 0;
    border: none;
    border-radius: 0;
}

.form-group {
    .control-label {
        width: 100%;
        padding-top: 0;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: left;

        .required {
            border: 1px solid $baseColorRed;
            padding-top: 0;
            font-size: 12px;
            color: $baseColorRed;
            padding: 0px 5px;
            padding-bottom: 2px;
            text-transform: capitalize;
            margin-left: 10px;
            font-family: Century751 No2;
        }
    }

    .col-sm-10 {
        width: 100%;
    }
}

.table {
    thead {
        font-size: 16px;

        tr {
            td {
                border-bottom-width: 1px;
            }
        }
    }

    tbody {
        tr {
            td {
                .btn {
                    padding: 0;
                    background-color: transparent;
                    color: $baseColorRed;

                    &:focus, &:focus:hover, &:active, &:active:hover {
                        background-color: transparent;
                        color: $baseColorRed;
                    }

                    &:nth-of-type(n+2) {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.input-group-addon {
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent!important;
    vertical-align: top;

    button, .button {
        padding-top: 18px;
        padding-bottom: 19px;

        &:active:hover {
            color: #fff;
            background-color: $baseColorRed;
        }
    }
}

.list-group-item {
    &:focus {
        background-color: transparent !important;
    }
}