.select-wrapper {
    position: relative;

    &:after {
        content: "\e259";
        color: $textColorGrey;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        z-index: -1;
        display: block;
        font-family: 'Glyphicons Halflings';
        font-size: 12px;
        line-height: 1;
        transition: all 0.3s;
    }

    &.opened {
        &:after {
            transform: translateY(-50%) rotateZ(180deg);
        }
    }

    select, select.form-control {
        display: inline-block;
        width: 100%;
        height: 54px;
        line-height: 54px;
        padding: 0 15px;
        border-color: $baseBorderColor;
        border-radius: 0;
        background-color: transparent;
        font-size: 1em;
        font-weight: 300;
        color: $textColorGrey;
        text-overflow: ellipsis;
        appearance: none !important;
        transition: all 0.3s;

        &::-ms-expand {
            display: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:hover {
            cursor: pointer;
        }

        option {
            font-weight: 300;
        }
    }

    i {
        display: none;
    }

    @include breakpoint(mobile) {
        select, select.form-control {
            padding-right: 40px;
        }
    }
}