body[class="account-edit"] {
    #content {
        .ulogin_profile {
            margin-top: 30px;
        }

        form {
            .form-group {
                &.birthday-wrapper,
                &.gender-wrapper,
                &.phone-wrapper,
                &.fax-wrapper {
                    float: left;
                    width: 50%;
                    margin-right: 0;
                    margin-left: 0;
                }

                &.birthday-wrapper,
                &.phone-wrapper {
                    .control-label,
                    .col-sm-10 {
                        padding-left: 0;
                    }
                }

                &.gender-wrapper,
                &.fax-wrapper {
                    .control-label,
                    .col-sm-10 {
                        padding-right: 0;
                    }
                }

                &.foto-wrapper {
                    margin-bottom: 25px;

                    .customer-photo {
                        display: block;
                        width: 100%;
                        max-width: 250px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-left: 0;
                    }

                    .success-upload-photo {
                        display: block;
                        margin-top: 10px;
                        color: green;
                    }
                }

                &.birthday-wrapper {
                    &.has-error + .gender-wrapper {
                        margin-bottom: 38px;
                    }
                }

                .no-valid-regexp,
                .error-message {
                    color: $baseColorRed;
                }
            }
        }
    }

    @include breakpoint(small) {
        #content {
            form {
                .form-group {
                    &.gender-wrapper,
                    &.phone-wrapper,
                    &.fax-wrapper,
                    &.birthday-wrapper {
                        width: auto;
                        min-width: 100%;

                        .control-label,
                        .col-sm-10 {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint(extra-small) {
        #content {
            form {
                .form-group {
                    &.foto-wrapper {
                        #customer_photo {
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}