.table-control {
    .text-right {
        text-align: center;
        vertical-align: middle;
    }

    .btn, .btn-info, .btn-danger {
        padding: 5px 15px;

        i {
            margin-right: 10px;

            &:before {
                display: inline-block;
            }
        }
    }
}