.counter-wrapper {
    .quantity {
        display: inline-block;
        width: 25px;
        font-weight: bold;
        font-size: 18px;
        line-height: 1;
        text-align: center;
    }

    .quantity-plus,
    .quantity-minus {
        display: inline-block;
        width: 26px;
        height: 26px;
        padding: 0;
        line-height: 26px;
        color: #000;
        text-align: center;
        background-color: #fce9da;
        user-select: none;

        // &:hover {
        //     background-color: #fce9da;
        // }

        &:focus,
        &:focus:hover {
            background-color: #fce9da;
        }

        &:hover {
            cursor: pointer;
        }
    }
}