.product-card {
    position: relative;
    width: calc(33.33% - 30px);
    margin-bottom: 30px;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px;
    border: 1px solid #dfdfdf;
    overflow: hidden;

    &:hover {
        .product-quick-view {
            top: 8px;
        }
    }

    // &:nth-of-type(5n) {
    //     @extend .wide;
    // }

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .days-left {
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 300;

        &.attention {
            color: $baseColorRed;
        }
    }

    .like {
        position: absolute;
        top: 12px;
        right: 15px;
    }

    .product-quick-view {
        position: absolute;
        top: -50px;
        left: 10px;
    }

    .remove {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        background-color: transparent;
        z-index: 3;

        &:before {
            content: '\e014';
            display: block;
            width: 20px;
            height: 20px;
            font-family: 'Glyphicons Halflings';
            font-size: 20px;
            line-height: 1;
            color: $baseColorRed;
        }

        &:hover {
            transform: rotateZ(90deg);
        }
    }

    .product-image-wrapper {
        float: left;
        width: 115px;
        height: 190px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;

        img {
            max-height: 100%;
        }
    }

    .product-description-wrapper {
        float: left;
        width: calc(100% - 115px);
        padding-left: 10px;

        h6 {
            min-height: calc(1em * 1.6 * 2);
            max-height: calc(1em * 1.6 * 2);
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 15px;
            margin-bottom: 20px;
            font-size: 11px;
            line-height: 1.6;
            font-weight: 700;
            text-align: left;

            &:hover {
                a {
                    color: $baseColorRed;
                }
            }
        }

        .price-wrapper {
            .price {
                display: inline-block;
                font-size: 18px;
                line-height: 1;
                font-weight: 700;
                color: $baseColorRed;
                vertical-align: top;

                .currency {
                    font-style: normal;
                }
            }

            .old-price {
                position: relative;
                top: -2px;
                display: inline-block;
                margin-left: 5px;
                font-size: 14px;
                font-weight: 400;
                color: #000;
                text-decoration: line-through;

                .currency {
                    font-style: normal;
                }
            }
        }

        ul.features {
            margin-top: 15px;

            li {
                max-height: calc(1em * 1.7);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 300;
                position: relative;
                width: 100%;
                margin-bottom: 0;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
                border: none;
            }
        }

        .counter-wrapper {
            position: absolute;
            left: 15px;
            bottom: 20px;
            z-index: 1;

            .quantity {
                &:before {
                    display: none;
                }
            }
        }

        .specifications {
            display: none;
        }
    }

    .purchase-wrapper {
        position: absolute;
        bottom: 20px;
        right: 15px;
        text-align: right;

        .button {
            max-width: calc(100% - 90px);
            min-width: 100px;
            padding: 0;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            text-transform: uppercase;

            &:hover {
                background-color: $baseColorRed;
            }
        }

        .share {
            display: none;
        }
    }

    &.recommended-product {
        width: 100%;

        .product-description-wrapper {
            h6 {
                min-height: calc(1em * 1.6);
                font-size: 14px;
                line-height: 1.5;
            }

            .price-wrapper {
                float: left;
            }

            ul.features {
                float: left;
                margin-top: 0;
                margin-left: 35px;
                line-height: 1;

                li {
                    display: inline-block;
                    width: auto;
                    line-height: 18px;

                    &:nth-child(n+2) {
                        margin-left: 30px;
                    }
                }
            }

            .specifications {
                display: block;
                float: left;
                width: 100%;
                margin-top: 20px;
                font-weight: 300;
                line-height: 1.5;
                color: $textColorGrey;

                span {
                    font-weight: 700;
                }
            }
        }

        .purchase-wrapper {
            right: auto;
            left: 0;
            width: 100%;
            padding-left: 107px;
            padding-right: 15px;

            .button {
                max-width: 140px;
            }

            .share {
                content: '\f1e0';
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                display: block;
                width: 20px;
                height: 20px;
                font-family: 'FontAwesome';
                font-size: 20px;
                line-height: 1;
                color: $baseBgColorPale;
            }
        }
    }

    &.sold {
        border-color: #b3556e;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(168, 50, 82, 0.8);
            z-index: 1;
            transition: 0.8s;
        }

        &:after {
            content: attr(sold-message);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 28px;
            line-height: 1;
            font-weight: 500;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            white-space: nowrap;
            z-index: 1;
        }

        &:hover {
            &:before {
                bottom: 100%;
            }

            &:after {
                display: none;
            }
        }

        .remove {
            &:before {
                color: #fff;
            }
        }

        // .product-description-wrapper {
        //     .price-wrapper {
        //         .price {
        //             display: none;
        //         }

        //         .old-price {
        //             display: none;
        //         }
        //     }
        // }

        .counter-wrapper {
            display: none;
        }

        .purchase-wrapper {
            .button {
                background-color: #ccc;
            }
        }
    }

    @include breakpoint(medium) {
        .like {
            top: 10px;
            right: 10px;
            width: 18px;
        }

        .purchase-wrapper {
            .button {
                font-size: 11px;
            }
        }

        &.recommended-product {
            .product-description-wrapper {
                ul.features {
                    margin-left: 25px;

                    li {
                        &:nth-child(n+2) {
                            margin-left: 15px;
                        }
                    }
                }
            }

            .purchase-wrapper {
                .button {
                    max-width: 100px;
                }
            }
        }
    }


    @include breakpoint(small) {
        padding-bottom: 60px;

        .like {
            top: 12px;
            right: 15px;
            width: 23px;
        }

        .product-image-wrapper {
            height: 200px;
        }

        .product-description-wrapper {
            ul.features {
                display: inline-block;

                li {
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            .counter-wrapper {
                position: relative;
                left: 0;
                bottom: 0;
                float: right;
                width: 56px;

                .quantity {
                    width: 100%;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .purchase-wrapper {
            .button {
                font-size: 12px;
            }
        }

        &.recommended-product {
            .product-description-wrapper {
                ul.features {
                    margin-left: 35px;

                    li {
                        &:nth-child(n+2) {
                            margin-left: 30px;
                        }
                    }
                }

                .counter-wrapper {
                    position: absolute;
                    left: 15px;
                    bottom: 20px;
                    width: auto;
                    float: none;

                    .quantity {
                        width: 25px;
                    }
                }

                .specifications {
                    float: left;
                    width: 100%;
                    margin-top: 20px;
                    font-weight: 300;
                    line-height: 1.5;
                    color: $textColorGrey;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }

        &.sold {
            &:after {
                font-size: 32px;
            }
        }
    }


    @include breakpoint(extra-small) {
        width: 100% !important;

        .product-description-wrapper {
            h6 {
                margin-bottom: 10px;
            }
        }

        .purchase-wrapper {
            width: calc(50% - 30px) !important;

            .button {
                min-width: 100% !important;
            }
        }

        &.recommended-product {
            border-color: $baseColorRed;

            .product-description-wrapper {
                h6 {
                    font-size: 11px;
                    line-height: 1.7;
                }

                .price-wrapper {
                    float: none;
                }

                ul.features {
                    float: none;
                    margin-top: 10px;
                    margin-left: 0;

                    li {
                        display: block;
                        line-height: 1.7;

                        &:nth-child(n+2) {
                            margin-left: 0;
                        }
                    }
                }

                .counter-wrapper {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    float: right;
                    width: 56px;

                    .quantity {
                        width: 100%;

                        &:before {
                            display: none;
                        }
                    }
                }

                .specifications {
                    float: left;
                    width: 100%;
                    margin-top: 20px;
                    font-weight: 300;
                    line-height: 1.5;
                    color: $textColorGrey;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .purchase-wrapper {
                left: auto;
                right: 15px;
                padding-left: 0;

                .share {
                    display: none;
                }
            }
        }
    }


    @include breakpoint(mobile) {
        .purchase-wrapper {
            width: calc(100% - 30px) !important;

            .button {
                min-width: calc(100% - 90px) !important;
            }
        }
    }
}