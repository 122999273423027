.product-quick-view {
    padding: 0;
    font-size: 20px;
    line-height: 1;
    color: $baseColorRed;
    background-color: transparent;
    transition: all 0.2s;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        color: $baseColorRed;
    }
}