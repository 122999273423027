.ulogin_panel {
    .panel-heading {
        margin-bottom: 20px;
    }

    .ulogin_form {
        [class^="ulogin-button-"] {
            transition: all 0.25s;

            &:hover {
                opacity: 1 !important;
                border-radius: 15px 0 !important;
            }
        }
    }

    .panel-heading {
        margin-bottom: 0;
    }

    .ulogin_accounts {
        .ulogin_provider {
            float: none;
        }
    }
}