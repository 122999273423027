[type="radio"] {
    display: none;

    &:checked + span,
    &:checked + label {
        border-color: $baseColorRed;

        &:before {
            width: 10px;
            height: 10px;
        }
    }

    & + span,
    & + label {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $baseBorderColor;
        border-radius: 50%;
        vertical-align: middle;
        transition: all 0.2s;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 0;
            height: 0;
            border-radius: 50%;
            background-color: $baseColorRed;
            transition: all 0.2s;
        }
    }
}